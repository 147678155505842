import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../store/slices/snackbar';
import { useNavigate } from 'react-router-dom';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, CLEAR_RESPONSE, OTP_RESPONSE, OTP_VERIFYRESPONSE } from 'store/actions';

import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    extraFieldVisible: false,
    pwdFieldVisible: false,
    otpFieldVisible: false,
    isInitialized: false,
    user: null,
    alertMessage: null,
    userType: 'System User',
    userID: null,
    otpResponse: {},
    otpVerifyResponse: {}
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const tempDispatch = useDispatch();
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const logout = async () => {
        setSession(null);
        const response = await axios.post('/logout', { isLogin: false });
        dispatch({ type: LOGOUT });
    };

    const init = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            const urlParams = new URLSearchParams(window.location.search);
            const tempToken = urlParams.get('token');
            if (serviceToken && verifyToken(serviceToken) && tempToken === null) {
                setSession(serviceToken);
                const response = await axios.get('/accoumt/me');
                const { userDetails } = response.data;
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: userDetails
                    }
                });
                // const tabVisibilityResult = await axios.get('systemUserTabVisibility');

                // const { success: tvSuccess, result: tvResult } = tabVisibilityResult?.data || {};
                // const [firstTransaction = {}] = tvResult || [];
                // const roles = firstTransaction.roles;
                // const count = firstTransaction.count || 0;
                // if (tvSuccess) {
                //     const tabVisibility = {
                //         externalReviewModule: count > 0,
                //         accountModule: roles.includes('Accounts Officer'),
                //         purchaseModule: roles.includes('Purchase Officer')
                //     };
                //     localStorage.setItem('tabVisibility', JSON.stringify(tabVisibility || {}));
                // }
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: LOGOUT
            });
        }
    };

    const handleOnlineStatusChange = () => {
        tempDispatch(
            openSnackbar({
                open: true,
                message: 'We are back online',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                variant: 'alert',
                close: false,
                alert: {
                    color: 'success', // or 'error', 'warning', 'info'
                    variant: 'filled'
                }
            })
        );
    };

    const handleOfflineStatusChange = () => {
        tempDispatch(
            openSnackbar({
                open: true,
                message:
                    'No Internet Connection: It appears that you are currently offline.Please check your internet connection and try again',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                variant: 'alert',
                close: false,
                alert: {
                    color: 'error', // or 'error', 'warning', 'info'
                    variant: 'filled'
                }
            })
        );
    };
    useEffect(() => {
        const initInterval = setInterval(init, 5 * 60 * 1000);
        return () => {
            clearInterval(initInterval);
        };
    }, []);

    useEffect(() => {
        init();
        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOfflineStatusChange);
    }, []);

    const login = async (type, email, password, confirmPassword, otp) => {
        dispatch({ type: CLEAR_RESPONSE });
        if (type === 'login') {
            const response = await axios.post('/login', { email, password, confirmPassword });
            const {
                serviceToken,
                login,
                available,
                message,
                OTPResMsg,
                stage,
                extra,
                pwdField,
                otpField,
                userType,
                userID,
                userDetails,
                success
            } = response.data;
            localStorage.setItem('userType', userType);
            const isLoggedIn = login;
            setSession(serviceToken);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn,
                    available,
                    message,
                    OTPResMsg,
                    stage,
                    extra,
                    pwdField,
                    otpField,
                    sanple: 'asdasasd',
                    userType,
                    userID,
                    user: userDetails,
                    success
                }
            });
        } else if (type === 'otpValidation') {
            const response = await axios.post('/otpVerification', { email, otp });
            const { message, success, user } = response.data;
            dispatch({
                type: OTP_VERIFYRESPONSE,
                payload: {
                    user,
                    message,
                    success
                }
            });
        } else {
            const response = await axios.post('/sendOtp', { email });
            const { message, success, user } = response.data;
            dispatch({
                type: OTP_RESPONSE,
                payload: {
                    message,
                    user,

                    success
                }
            });
        }
    };

    const externalReviewAuthendication = async (data) => {
        try {
            const { token, operation, otp } = data;
            const response = await axios.post(
                '/authenticateExternalUser',
                { token, operation, otp },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const { data: responseData = {} } = response || {};
            const { response: externalUserResponse = {} } = responseData;
            const { userType, isLoggedIn, available, success, message, valid, mailID, opetation: responseOperation } = externalUserResponse;
            // if (valid === false) {
            //     navigate('/404-page-not-found');
            //     return true;
            // }
            if (valid === false) {
                navigate('/link-expired');
                return true;
            }
            // if (responseOperation === 'VOTP' && userType === 'External User' && isLoggedIn && success) {
            //     console.log(externalUserResponse);
            // }
            localStorage.setItem('userType', userType);
            localStorage.setItem('EmailID', mailID);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn,
                    available,
                    userType,
                    success,
                    message
                }
            });
            if (isLoggedIn && responseOperation === 'VOTP' && success && userType === 'External User') {
                // navigate('/ExternalReviewerProjectList');
                window.location.href = '/ExternalReviewerProjectList';
                // window.location.reload();
            }
            return response;
        } catch (error) {
            console.error('Error:', error);
            return error;
        }
    };

    const getLogin = async () => {
        // const response = await axios.get('/login');
        await axios.get('/login');
        // const { serviceToken, login, available, message, stage, extra } = response.data;
        // const isLoggedIn = login;
        // setSession(serviceToken);
        // dispatch({
        //     type: LOGIN,
        //     payload: {
        //         isLoggedIn,
        //         available,
        //         message,
        //         stage,
        //         extra
        //     }
        // });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const resetPassword = (email) => console.log(email);
    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                CLEAR_RESPONSE,
                getLogin,
                logout,
                register,
                resetPassword,
                updateProfile,
                externalReviewAuthendication
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
