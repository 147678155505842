import { v4 as uuidv4 } from 'uuid';
import ReactHtmlParser from 'html-react-parser';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const ajaxStartEvent = new CustomEvent('ajaxStart');
document.addEventListener('ajaxStart', (event) => {
    const targetElement = document.querySelector('.overlayNeed');
    const targetButtons = document.querySelectorAll('.disableNeedBtn');
    targetButtons.forEach((button) => {
        button.classList.add('disabledButton');
    });
    if (targetElement) {
        targetElement.classList.add('overlay');
    }
});

const ajaxEndEvent = new CustomEvent('ajaxEnd');
document.addEventListener('ajaxEnd', (event) => {
    const targetElement = document.querySelector('.overlayNeed');
    const targetButtons = document.querySelectorAll('.disableNeedBtn');
    if (targetButtons) {
        const targetButtons = document.querySelectorAll('.disableNeedBtn');
        targetButtons.forEach((button) => {
            button.classList.remove('disabledButton');
        });
    }
    if (targetElement) {
        // Check if the element exists
        // Remove the overlay class with a slight delay
        setTimeout(() => {
            targetElement.classList.remove('overlay');
        }, 500); // Adjust the delay as needed
    }
});
export function simulateAJAXStart() {
    document.dispatchEvent(ajaxStartEvent);
}

export function simulateAJAXEnd() {
    document.dispatchEvent(ajaxEndEvent);
}
const handlePopstate = () => {
    const serviceToken = localStorage.getItem('serviceToken');
    if (serviceToken === null) {
        window.location.href = '/login';
    }
};

window.addEventListener('popstate', handlePopstate);

export function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const date = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${date}`;
    return formattedDate;
}

export function calculateCumulativeAmount(mainCreatedDate, data, type) {
    const result = {};

    data.forEach((entry) => {
        const diff =
            type === 'Yearly'
                ? entry.createdDate.getFullYear() - mainCreatedDate.getFullYear()
                : (entry.createdDate.getFullYear() - mainCreatedDate.getFullYear()) * 12 +
                  (entry.createdDate.getMonth() - mainCreatedDate.getMonth());

        const key = diff + 1;

        if (!result[key]) {
            result[key] = entry.amount;
        } else {
            result[key] += entry.amount;
        }
    });

    return result;
}

export function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function calculateCurrentInterval(selectedCreatedDate, currentDate, selectedType, selectedNoOfInterval) {
    const selectedCreatedDateObj = new Date(selectedCreatedDate);
    const currentDateObj = new Date(currentDate);
    let currentInterval = null;
    const daysInMonth = [];
    const daysInYear = [];
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    let diffDays = Math.round(Math.abs((selectedCreatedDateObj - currentDateObj) / oneDay));
    let tempcreatedate = new Date(selectedCreatedDateObj);
    if (selectedType === 'Monthly') {
        for (let i = 0; i < selectedNoOfInterval; i += 1) {
            let tempMonth = (tempcreatedate.getMonth() + 1).toString().padStart(2, '0');
            let tempYear = tempcreatedate.getFullYear();
            const tempDate = tempcreatedate.getDate().toString().padStart(2, '0');
            tempMonth = parseInt(tempMonth, 10); // Convert the string to an integer using parseInt
            if (tempMonth + 1 <= 12) {
                tempMonth += 1;
            } else {
                tempMonth = '1';
                tempYear += 1;
            }
            const formattedDate = `${tempYear}-${tempMonth}-${tempDate}`;
            // Calculating the time difference in milliseconds
            const timeDifference = Math.abs(new Date(formattedDate) - tempcreatedate);

            // Converting the time difference to days
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            daysInMonth.push(daysDifference);
            tempcreatedate = new Date(formattedDate); // Create a new Date object
        }
    }
    if (selectedType === 'Yearly') {
        for (let i = 0; i < selectedNoOfInterval; i += 1) {
            const year = tempcreatedate.getFullYear();
            const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
            let daysInCurrentYear = 365;
            if (isLeapYear) {
                daysInCurrentYear = 366;
            }
            daysInYear.push(daysInCurrentYear);
            tempcreatedate = new Date(selectedCreatedDateObj.getFullYear() + 1, 0, 1); // Move to the next year
        }
    }
    if (selectedType === 'Monthly') {
        // Calculate the current month interval
        let month = 0;
        while (diffDays > daysInMonth[month]) {
            diffDays -= daysInMonth[month];
            month += 1;
        }

        // Adjust month (January is month 1, February is month 2, etc.)
        month += 1;
        currentInterval = month;
    } else if (selectedType === 'Yearly') {
        let year = 0;
        while (diffDays > daysInYear[year]) {
            diffDays -= daysInYear[year];
            year += 1;
        }

        // Adjust month (January is month 1, February is month 2, etc.)
        year += 1;
        currentInterval = year;
    }
    return currentInterval;
}
export function convertDateFormat(date) {
    const parsedDate = new Date(date);
    const day = parsedDate.getDate().toString().padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = parsedDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

export function convertDateTimeFomat(dateTime) {
    const formattedDate = dayjs.utc(dateTime).format('DD MMM YYYY h:mm A');
    return formattedDate;
}

export const generateUniqueId = (starterString) => {
    const fullUuid = uuidv4();
    if (starterString) {
        return `${starterString}-${fullUuid}`;
    }
    return fullUuid;
};
const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

// to get date in this format 8th Apr 2024
export function formatDateWithOrdinal(input) {
    const dateObject = new Date(input);
    const day = dateObject.getDate();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month} ${year}`;
}

export function convertToCustomFormat(timestamp) {
    // Create a new Date object from the provided timestamp
    const date = new Date(timestamp);

    // Add the offset for IST (Indian Standard Time)
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    // Extract the components of the date
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const day = date.getDate();

    // Extract the time components
    let hours = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2); // Ensure two digits for minutes

    // Determine AM or PM
    const meridiem = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Construct the formatted date string
    const formattedDate = `${month} ${day} ${year} at ${hours}:${minutes} ${meridiem}`;

    return formattedDate;
}

export function HtmlRenderer({ htmlContent }) {
    return <div>{ReactHtmlParser(htmlContent)}</div>;
}

export const statusColors = {
    Draft: { backgroundColor: '#ced4da', textColor: '#495057' },
    Approved: { backgroundColor: '#b9f6ca60', textColor: '#00c853' },
    Rejected: { backgroundColor: '#fbe9e780', textColor: '#d84315' },
    'On Hold': { backgroundColor: '#fff8e1', textColor: '#ffc107' },
    Pending: { backgroundColor: '#e1d2fa', textColor: '#673ab7' },
    Completed: { backgroundColor: '#b9f6ca60', textColor: '#00c853' },
    Active: { backgroundColor: '#b9f6ca60', textColor: '#00c853' },
    Deactivated: { backgroundColor: '#fbe9e780', textColor: '#d84315' },
    Inactive: { backgroundColor: '#fef8e0', textColor: '#ffc107' },
    'In Active': { backgroundColor: '#fff8e1', textColor: '#ffc107' },
    Crossed: { backgroundColor: '#dbe4ff', textColor: '#748ffc' }
};
// export function convertIstTime(timeStamp) {
//     const utcDate = new Date(timeStamp);
//     console.log(utcDate);

//     // Add IST offset of +5:30 hours
//     utcDate.setHours(utcDate.getHours() + 5);
//     utcDate.setMinutes(utcDate.getMinutes() + 30);

//     // Convert to IST date string
//     const istDateTime = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
//     console.log(new Date(istDateTime));

//     return utcDate;
// }
