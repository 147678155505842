// action - state management
import { LOGIN, LOGOUT, REGISTER, CLEAR_RESPONSE, OTP_RESPONSE, OTP_VERIFYRESPONSE } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    referesh: false
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { isLoggedIn, extra, pwdField, message, OTPResMsg, userType, stage, otpField, userID, user, success } = action.payload;
            const tempOtpResponse = isLoggedIn ? {} : state.otpResponse;
            const tempVerifyResponse = isLoggedIn ? {} : state.otpVerifyResponse;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                extraFieldVisible: extra,
                pwdFieldVisible: pwdField,
                otpFieldVisible: otpField,
                otpVerifyResponse: tempVerifyResponse,
                otpResponse: tempOtpResponse,
                alertMessage: { message, success },
                userType,
                OTPResMsg,
                user,
                referesh: stage === 'setPassword',
                userID
            };
        }
        case CLEAR_RESPONSE: {
            return {
                ...state,
                alertMessage: null,
                otpResponse: {},
                otpVerifyResponse: {}
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case OTP_RESPONSE: {
            return {
                ...state,
                otpResponse: { user: action.payload.user, success: action.payload.success, message: action.payload.message },
                alertMessage: { message: action.payload.message, success: action.payload.success }
            };
        }
        case OTP_VERIFYRESPONSE: {
            return {
                ...state,
                otpVerifyResponse: { user: action.payload.user, success: action.payload.success },
                alertMessage: { message: action.payload.message, success: action.payload.success }
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
