// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */

    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="150.000000pt"
        height="30.000000pt"
        viewBox="0 0 2187.000000 460.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,460.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path
                d="M2070 4383 c-14 -2 -53 -13 -87 -23 -34 -11 -99 -29 -145 -39 -46
  -11 -97 -25 -113 -31 -17 -7 -66 -20 -110 -30 -44 -10 -116 -28 -160 -40 -44
  -12 -128 -35 -187 -51 -60 -16 -156 -42 -215 -58 -60 -17 -121 -33 -138 -36
  -16 -3 -66 -17 -110 -30 -44 -13 -98 -26 -120 -30 l-40 -7 25 -13 c14 -8 43
  -17 65 -20 22 -4 85 -19 140 -35 179 -51 291 -80 305 -80 8 0 61 -14 117 -32
  l103 -31 0 -149 0 -148 -585 0 -585 0 0 -143 c0 -102 5 -159 16 -198 44 -149
  211 -231 342 -168 80 39 151 133 152 202 l0 27 84 0 83 0 -5 -807 c-5 -947 -1
  -1062 53 -1233 19 -61 46 -131 60 -157 14 -26 25 -52 25 -56 0 -5 14 -29 30
  -54 16 -24 30 -47 30 -50 0 -6 21 -32 110 -139 79 -95 215 -204 325 -262 109
  -57 174 -83 283 -114 96 -27 125 -31 287 -35 l180 -5 139 37 c147 38 307 109
  418 186 104 71 252 229 333 354 29 44 105 198 105 212 0 6 9 32 20 58 11 26
  29 105 40 175 19 121 20 179 20 1009 l0 881 80 0 80 0 0 -29 c0 -37 36 -105
  76 -146 129 -131 345 -82 413 93 18 45 21 75 21 207 l0 155 -580 0 -580 0 0
  149 0 150 84 26 c47 14 100 28 118 31 18 4 69 16 113 29 87 25 176 48 270 71
  33 8 85 22 115 31 l55 17 -45 9 c-25 5 -88 22 -140 37 -52 15 -111 31 -130 34
  -19 4 -71 17 -115 30 -44 13 -134 38 -200 55 -66 18 -154 41 -195 53 -41 11
  -115 31 -165 43 -138 35 -212 55 -305 82 -144 42 -188 48 -260 36z m140 -36
  c30 -9 91 -25 135 -37 44 -13 121 -33 170 -46 133 -34 240 -62 335 -88 47 -13
  139 -38 205 -55 66 -17 154 -41 195 -53 41 -11 113 -30 158 -40 46 -11 80 -24
  75 -28 -4 -5 -39 -16 -78 -26 -38 -9 -115 -29 -170 -44 -55 -15 -120 -32 -145
  -39 -128 -31 -184 -46 -274 -72 -54 -16 -107 -29 -116 -29 -9 0 -48 -11 -87
  -24 -38 -13 -90 -27 -114 -31 -24 -4 -63 -13 -86 -21 -23 -7 -95 -27 -160 -44
  l-118 -31 -90 22 c-49 12 -119 30 -153 41 -35 11 -89 26 -120 33 -32 6 -93 22
  -137 34 -121 34 -278 76 -395 106 -58 15 -145 38 -195 52 -49 14 -117 31 -150
  38 -89 20 -123 34 -105 45 8 5 35 12 60 16 24 4 85 20 135 35 49 16 96 29 103
  29 8 0 62 13 120 30 59 16 161 43 227 61 66 17 165 43 220 59 106 29 162 44
  344 90 62 16 116 32 119 35 3 3 13 3 21 1 9 -3 41 -11 71 -19z m1788 -1029
  l-3 -153 -34 -51 c-47 -70 -87 -95 -164 -101 -79 -7 -126 8 -173 52 -36 35
  -74 116 -74 160 l0 25 -110 0 -110 0 0 -897 c0 -952 0 -958 -49 -1148 l-17
  -70 -55 -6 c-32 -3 -41 -6 -22 -7 59 -3 63 -8 44 -53 l-16 -39 -125 -2 -125
  -2 123 -3 123 -4 -22 -39 -23 -40 -73 0 c-74 0 -215 -24 -243 -41 -16 -10 10
  -6 84 13 44 11 204 20 217 12 5 -2 -4 -22 -19 -44 -22 -32 -34 -40 -62 -41
  -31 -2 -32 -3 -7 -6 15 -2 27 -7 27 -11 0 -4 -18 -26 -40 -50 -32 -34 -46 -42
  -77 -43 l-38 -2 33 -6 33 -6 -30 -30 c-52 -52 -67 -65 -79 -65 -7 0 -24 -14
  -39 -31 -24 -27 -64 -54 -156 -106 -12 -7 -53 -14 -92 -14 l-70 -2 55 -6 55
  -6 -40 -17 c-110 -46 -143 -53 -188 -39 -75 22 -93 22 -37 -1 l54 -22 -34 -7
  c-117 -24 -271 -32 -386 -20 -66 7 -132 15 -149 19 -28 5 -26 6 30 28 33 13
  52 23 42 23 -10 1 -34 -6 -54 -14 -44 -19 -90 -19 -134 -2 -19 8 -56 22 -84
  33 l-50 19 60 6 60 6 -73 1 c-51 2 -84 8 -110 22 -95 51 -157 101 -141 111 8
  5 -1 11 -27 18 -23 7 -58 30 -84 56 l-45 45 45 1 c51 1 45 5 -21 14 -38 5 -50
  12 -78 48 l-32 42 38 6 38 6 -45 2 c-40 2 -48 6 -68 37 -12 20 -20 39 -18 43
  8 12 114 13 176 2 125 -22 129 -22 80 -5 -25 9 -96 18 -161 21 l-117 6 -16 34
  c-9 18 -16 36 -16 38 0 3 59 5 132 5 72 0 129 2 126 5 -6 6 -236 8 -258 3 -13
  -3 -46 69 -36 79 3 4 25 10 49 14 40 8 40 8 -11 5 -64 -4 -62 -7 -100 159
  l-26 110 -3 928 -4 927 -109 0 -110 0 0 -32 c0 -47 -41 -128 -83 -165 -41 -36
  -100 -49 -180 -39 -59 6 -88 25 -134 85 -44 57 -53 96 -53 244 l0 127 1871 0
  1871 0 -4 -152z"
            />
            <path
                d="M2027 3433 c-10 -9 -8 -413 1 -413 12 0 37 316 33 403 -1 17 -21 23
  -34 10z"
            />
            <path
                d="M1943 3363 c5 -38 20 -121 33 -186 28 -138 31 -180 7 -127 -9 19 -26
  73 -38 120 -19 75 -67 240 -71 248 0 1 -9 -1 -18 -4 -20 -8 -21 -3 27 -134 19
  -52 49 -137 67 -188 19 -51 38 -91 44 -89 13 5 10 124 -5 267 -15 144 -19 160
  -38 160 -15 0 -16 -7 -8 -67z"
            />
            <path
                d="M2098 3287 c-15 -78 -30 -167 -33 -197 l-5 -55 14 45 c7 25 23 83 35
  130 12 47 28 111 36 142 19 73 19 78 -2 78 -15 0 -23 -24 -45 -143z"
            />
            <path
                d="M2202 3373 c-5 -16 -17 -48 -25 -73 -60 -181 -103 -283 -117 -275 -5
  3 -10 1 -10 -4 0 -6 4 -11 8 -11 5 0 15 -11 22 -25 7 -14 18 -25 24 -24 25 3
  37 -2 34 -13 -6 -17 23 -43 63 -57 18 -6 50 -29 71 -50 20 -21 39 -37 41 -35
  4 5 51 -5 171 -37 70 -19 86 -18 86 5 0 8 -51 22 -145 41 -165 32 -209 55 -87
  43 42 -3 114 -7 160 -7 76 -1 82 1 82 19 0 19 -7 20 -180 20 -192 0 -241 13
  -87 23 154 10 267 28 267 43 0 7 -3 15 -7 17 -10 6 -158 -16 -286 -43 -113
  -23 -120 -23 -113 -11 6 9 66 29 171 56 158 40 210 60 207 78 -4 22 -9 22 -84
  -7 -35 -13 -79 -29 -98 -36 -19 -6 -71 -25 -115 -41 -166 -60 -108 -21 130 88
  131 60 148 72 135 89 -16 18 -35 9 -262 -128 -142 -85 -146 -74 -11 28 29 22
  167 118 209 145 21 14 22 18 10 34 -12 17 -25 8 -176 -119 -90 -75 -166 -134
  -168 -131 -3 3 37 48 89 100 226 228 205 203 185 223 -14 13 -19 14 -23 4 -7
  -18 -267 -322 -271 -317 -4 4 76 126 148 225 22 30 43 62 46 70 4 8 16 25 28
  38 17 20 18 24 6 32 -8 5 -18 10 -22 10 -6 0 -47 -63 -60 -94 -5 -10 -18 -31
  -29 -48 -21 -29 -57 -93 -90 -159 -18 -36 -49 -69 -49 -52 0 5 4 13 9 18 4 6
  17 33 27 60 10 28 28 67 41 87 13 21 23 42 23 47 0 6 16 44 36 85 19 42 33 80
  29 86 -12 19 -32 10 -43 -17z"
            />
            <path
                d="M1763 3374 c-15 -6 -2 -40 39 -101 15 -23 28 -44 28 -47 0 -3 26 -49
  59 -103 59 -99 96 -182 52 -118 -11 17 -37 53 -58 81 -21 28 -64 87 -95 130
  -93 127 -86 120 -103 104 -12 -13 -1 -31 96 -145 61 -72 124 -145 140 -162 29
  -33 38 -63 18 -63 -5 0 -8 4 -5 8 3 5 -64 77 -147 160 -139 139 -154 151 -171
  139 -20 -14 -21 -14 211 -205 63 -52 106 -92 98 -92 -8 0 -139 85 -158 103 -6
  7 -23 18 -37 25 -14 7 -54 33 -89 58 -35 25 -70 43 -77 40 -18 -7 -18 -36 -1
  -36 8 0 32 -12 53 -28 21 -15 50 -33 64 -40 14 -6 52 -27 85 -47 33 -19 86
  -49 119 -65 64 -34 71 -52 9 -25 -71 32 -158 71 -208 95 -112 52 -163 69 -169
  55 -8 -21 19 -35 144 -77 181 -61 256 -92 250 -103 -6 -12 -132 -13 -307 -2
  -118 8 -133 7 -133 -7 0 -14 25 -16 215 -16 118 0 215 -4 215 -9 0 -9 -136
  -28 -275 -36 -138 -9 -155 -13 -155 -35 0 -25 13 -25 136 1 112 23 277 49 283
  43 2 -2 -2 -5 -10 -8 -148 -44 -228 -67 -294 -85 -89 -25 -98 -30 -86 -49 7
  -11 26 -7 97 18 213 77 257 91 262 85 8 -8 42 26 42 42 0 7 4 13 8 13 5 0 17
  7 28 15 19 15 19 15 -1 8 -19 -7 -19 -6 -4 17 11 16 33 29 65 37 l49 13 -42
  -5 c-42 -5 -53 1 -33 21 6 6 -16 61 -64 159 -40 83 -83 171 -94 198 -21 46
  -25 50 -49 41z"
            />
            <path
                d="M1480 3001 c0 -22 12 -27 95 -36 33 -4 113 -18 177 -32 142 -30 189
  -26 63 5 -49 12 -139 36 -200 52 -129 36 -135 36 -135 11z"
            />
            <path
                d="M2050 2920 c-20 -6 -21 -8 -5 -8 11 0 34 4 50 8 27 7 27 8 5 8 -14 0
  -36 -4 -50 -8z"
            />
            <path d="M2083 2893 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
            <path
                d="M1982 2873 c-64 -23 -46 -25 23 -3 33 10 51 19 40 19 -11 0 -39 -7
  -63 -16z"
            />
            <path
                d="M2145 2880 c-4 -6 -10 -8 -15 -5 -5 3 -11 1 -15 -5 -3 -5 -17 -10
  -31 -10 -18 0 -24 -5 -24 -23 0 -13 5 -28 12 -35 18 -18 1 -29 -67 -44 -46
  -11 -60 -18 -59 -31 1 -9 -4 -17 -12 -17 -8 0 -14 -5 -14 -11 0 -5 -8 -9 -17
  -7 -10 1 -24 -1 -31 -6 -18 -11 -23 -4 -29 42 -4 33 -2 42 17 55 14 10 17 16
  9 19 -7 2 -50 -15 -96 -39 -45 -24 -87 -43 -92 -43 -6 0 -33 -13 -61 -30 -28
  -16 -58 -30 -66 -30 -19 0 -30 -28 -16 -42 8 -8 25 -2 59 18 26 16 59 36 73
  43 14 8 57 33 96 56 57 34 70 39 67 24 -3 -10 -19 -27 -37 -39 -17 -11 -56
  -38 -86 -61 -30 -22 -72 -52 -93 -65 -26 -17 -37 -30 -33 -40 3 -9 6 -18 6
  -20 0 -8 44 18 70 42 100 90 169 143 175 134 7 -12 -41 -68 -136 -159 -50 -48
  -56 -57 -47 -75 10 -19 14 -17 54 29 l44 50 -7 -35 c-12 -67 -20 -80 -52 -80
  -24 0 -29 -5 -35 -31 -9 -47 -7 -108 4 -130 6 -11 21 -19 35 -19 l25 0 -26
  -39 c-58 -89 -64 -223 -14 -327 26 -52 69 -80 100 -64 17 9 21 6 29 -22 8 -26
  10 -13 8 64 l-2 97 42 10 c61 16 72 14 79 -14 5 -20 12 -25 38 -24 29 1 29 1
  6 8 -36 10 -52 47 -46 106 l5 50 -16 -38 c-12 -29 -20 -36 -32 -31 -9 3 -23 6
  -31 6 -17 1 -60 37 -60 52 0 5 14 -3 30 -19 17 -16 30 -24 30 -19 0 5 -16 22
  -35 38 -28 24 -33 34 -28 55 14 58 43 110 74 132 18 12 29 18 25 11 -9 -15 13
  -80 35 -105 14 -16 20 -17 37 -6 12 7 24 10 28 6 11 -11 -28 -37 -45 -31 -10
  3 -9 -2 4 -16 10 -11 21 -17 24 -13 3 5 13 9 21 9 8 1 23 5 33 9 26 11 20 -8
  -9 -26 -24 -15 -24 -16 -8 -30 10 -8 15 -9 11 -3 -4 7 -1 17 7 23 10 9 13 5
  14 -20 1 -49 12 -47 12 2 0 35 6 52 30 79 33 39 39 92 15 124 -8 11 -15 34
  -15 50 -1 26 -2 28 -11 13 -5 -9 -8 -21 -4 -26 3 -5 1 -12 -5 -16 -18 -11 -50
  16 -75 65 -34 67 -45 71 -45 16 0 -39 35 -125 46 -113 6 6 -20 67 -28 67 -4 0
  -8 9 -8 21 0 30 23 18 34 -17 17 -59 38 -90 71 -109 19 -10 35 -22 35 -27 0
  -14 -16 -9 -55 21 -22 16 -44 27 -50 25 -6 -3 -20 11 -30 29 -10 19 -25 34
  -34 33 -10 -1 -25 18 -40 50 -14 27 -39 61 -57 75 -28 21 -30 26 -19 39 8 9
  12 25 9 36 -6 24 19 54 45 54 21 0 44 -17 60 -45 11 -18 12 -18 7 7 -3 14 -3
  28 2 31 4 2 1 2 -6 0 -24 -6 -6 21 29 45 37 26 67 26 150 2 43 -13 49 -12 99
  13 30 15 47 27 37 27 -22 0 -67 -21 -67 -32 0 -11 -47 -10 -54 1 -9 14 4 41
  20 41 20 0 17 -18 -3 -23 -14 -4 -12 -5 5 -6 14 -1 22 5 22 15 0 8 4 12 10 9
  5 -3 23 1 40 10 43 22 86 17 82 -10 -2 -19 -2 -19 11 -3 12 15 16 16 32 4 18
  -13 17 -14 -13 -21 -25 -5 -32 -12 -32 -31 0 -17 -6 -24 -20 -24 -19 0 -64
  -39 -55 -48 3 -3 15 4 28 16 29 27 47 28 47 3 0 -13 -8 -21 -22 -23 -35 -5
  -35 -36 -1 -40 22 -2 29 -10 35 -33 4 -20 6 -8 5 35 -2 71 4 87 37 101 16 7
  14 8 -11 3 -41 -7 -42 1 -3 21 32 17 54 11 81 -21 13 -16 12 -17 -14 -10 -19
  4 -26 3 -22 -4 3 -5 13 -10 21 -10 20 0 18 -16 -7 -49 -11 -15 -27 -58 -36
  -95 -15 -68 -32 -88 -57 -67 -7 6 -16 8 -20 4 -3 -4 -12 1 -19 10 -10 14 -12
  4 -12 -57 0 -41 3 -69 7 -63 4 7 8 23 8 37 0 14 5 32 10 40 8 13 11 13 24 0
  11 -11 13 -24 6 -50 l-8 -35 -2 30 c-1 17 -4 23 -7 15 -11 -34 -27 -60 -37
  -60 -13 -1 -45 -46 -77 -107 l-22 -41 23 -62 c20 -54 21 -66 11 -101 -7 -22
  -9 -49 -5 -61 4 -15 3 -18 -6 -13 -9 6 -11 4 -5 -4 8 -14 -43 -74 -56 -65 -12
  7 -38 -37 -32 -54 4 -8 2 -13 -3 -10 -5 4 -10 -5 -11 -19 -1 -22 3 -19 25 19
  15 25 41 59 58 74 37 34 69 94 78 144 l6 35 -15 -40 c-9 -22 -21 -44 -27 -50
  -8 -7 -9 -4 -3 10 11 29 45 139 45 145 -1 3 -7 -6 -14 -19 l-13 -24 -17 24
  c-9 13 -16 29 -16 35 0 13 28 28 48 25 6 -1 12 5 12 14 0 8 5 15 11 15 6 0 8
  -8 5 -17 -3 -10 8 -2 25 17 24 27 28 29 19 10 -19 -37 -47 -70 -53 -64 -3 3
  -3 -3 0 -15 5 -19 7 -17 29 15 13 19 24 38 24 42 0 5 14 27 31 50 17 24 29 45
  26 48 -3 3 -13 -8 -22 -26 -9 -18 -19 -30 -21 -27 -3 2 4 22 15 43 10 22 22
  63 26 92 6 46 10 52 30 52 32 0 80 -57 64 -76 -6 -8 -14 -40 -18 -71 -7 -63
  -24 -79 -39 -36 -6 15 -11 21 -11 12 -1 -9 6 -33 14 -52 25 -61 13 -139 -17
  -109 -17 17 -33 15 -18 -3 14 -17 3 -20 -37 -9 -27 7 -30 21 -5 27 10 3 7 5
  -10 6 -21 1 -26 -3 -22 -14 5 -11 2 -13 -10 -9 -22 9 -20 -9 4 -31 19 -17 20
  -17 14 -1 -6 14 -2 16 22 11 46 -10 84 -27 84 -37 0 -17 -30 -20 -61 -4 -17 9
  -36 13 -41 10 -5 -3 -15 0 -23 6 -11 9 -15 9 -15 1 0 -6 11 -17 25 -23 17 -8
  24 -17 20 -27 -5 -13 -4 -14 6 -3 20 19 59 15 59 -7 0 -10 14 -32 31 -49 18
  -18 28 -35 24 -42 -6 -9 -2 -9 12 -1 15 7 23 7 32 -2 10 -9 11 -8 6 6 -4 10
  -2 17 4 17 6 0 11 5 11 10 0 7 -6 7 -19 0 -25 -13 -34 -13 -26 0 3 6 1 10 -6
  10 -8 0 -6 6 4 18 26 29 20 38 -8 12 -33 -31 -41 -21 -13 16 l23 29 -32 -23
  c-35 -25 -42 -20 -18 12 8 11 15 28 15 38 0 12 8 18 23 19 17 1 16 3 -8 9
  l-30 7 27 7 c29 9 32 8 88 -24 l35 -20 -30 25 c-16 15 -39 30 -49 34 -17 7
  -14 12 27 35 26 16 47 30 47 32 0 12 -32 1 -57 -19 -45 -37 -59 -39 -61 -7 -2
  22 7 34 45 64 50 39 61 41 77 10 6 -10 21 -32 34 -47 27 -35 28 -56 1 -114
  -16 -36 -23 -43 -31 -32 -7 10 -8 8 -3 -8 3 -13 2 -31 -4 -40 -9 -16 -10 -16
  -11 4 0 24 -45 83 -63 83 -7 0 -4 -5 6 -11 9 -5 24 -23 31 -38 13 -24 13 -31
  1 -48 -12 -18 -12 -22 -1 -26 7 -3 18 -1 23 4 7 7 14 -5 22 -35 14 -57 14 -89
  0 -81 -5 4 -3 -9 5 -29 8 -20 11 -36 5 -36 -20 0 -7 -26 14 -28 14 -1 24 4 24
  12 0 8 -6 12 -12 10 -6 -3 -12 1 -12 8 0 7 -2 18 -3 23 -2 6 0 23 5 39 5 20 3
  45 -9 80 l-17 51 31 68 c22 47 31 77 28 99 -3 19 5 57 20 97 14 36 27 81 29
  99 3 18 9 35 14 38 14 8 10 50 -6 64 -8 7 -16 22 -16 34 -1 20 -2 20 -6 1 -10
  -45 -12 -48 -29 -35 -12 11 -20 9 -44 -9 -36 -26 -41 -7 -8 29 12 12 16 21 8
  18 -16 -5 -62 -86 -55 -98 3 -4 -1 -21 -9 -37 -8 -15 -18 -37 -22 -48 -6 -19
  -7 -18 -14 5 l-8 25 -1 -27 c-1 -16 -5 -28 -11 -28 -15 0 -12 64 5 86 14 18
  14 18 15 -4 0 -13 4 -20 10 -17 13 8 -25 83 -54 106 -11 10 -33 21 -48 24 -25
  6 -26 8 -17 43 30 114 55 146 70 89 4 -15 18 -39 30 -55 l24 -28 21 25 c13 15
  31 24 50 24 27 0 29 4 32 40 2 30 -4 49 -24 78 -15 22 -32 39 -39 39 -8 0 -37
  16 -66 35 -30 19 -57 35 -62 35 -16 0 -77 52 -77 66 0 14 58 -4 138 -45 64
  -32 79 -34 85 -11 4 17 -3 22 -51 36 -32 9 -82 28 -112 41 -44 19 -64 23 -103
  18 -42 -6 -52 -4 -75 16 -21 19 -23 19 -9 3 9 -11 17 -34 16 -50 -1 -27 -1
  -28 -8 -5 -9 33 -38 51 -51 31 -9 -13 -11 -13 -20 0 -8 13 -11 13 -25 -1 -9
  -9 -17 -26 -18 -38 -1 -14 -6 -20 -15 -16 -7 3 -20 5 -28 5 -21 0 -17 21 5 27
  25 6 51 54 34 60 -22 8 -14 33 15 45 15 6 31 10 36 9 4 -1 14 3 22 10 7 6 18
  8 24 4 5 -3 10 -1 10 4 0 14 -37 14 -45 1z m35 -120 c0 -5 -2 -10 -4 -10 -3 0
  -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m130 -5 c0 -8 -4 -15 -10 -15
  -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-35 -5 c3 -5 3 -10 -2
  -10 -4 0 -18 -3 -30 -6 -13 -4 -23 -2 -23 4 0 9 15 16 42 21 4 0 10 -3 13 -9z
  m-266 -29 c-15 -5 -22 6 -14 19 6 9 10 9 16 -1 5 -8 4 -15 -2 -18z m164 9 c23
  -27 22 -29 -20 -27 -37 1 -53 11 -53 32 0 6 4 4 9 -3 7 -11 9 -11 14 2 9 22
  29 20 50 -4z m-193 -6 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8
  -4 15 -8 15 -10z m328 -12 c3 -9 -3 -11 -22 -6 -17 4 -37 -1 -61 -15 -44 -26
  -47 -8 -2 19 35 22 78 23 85 2z m-228 -17 c19 -10 28 -22 27 -35 -1 -19 -6
  -20 -42 -14 -22 3 -42 6 -45 6 -33 1 -53 -6 -79 -28 -36 -30 -42 -12 -8 24
  l22 25 -24 -13 c-13 -6 -26 -21 -28 -31 -3 -10 -9 -19 -14 -19 -11 0 -11 3 1
  34 21 57 125 85 190 51z m-197 -33 c-5 -22 -23 -31 -23 -12 0 10 23 40 27 36
  1 -1 0 -12 -4 -24z m-47 -54 c-8 -12 -27 -22 -41 -24 l-25 -3 32 39 c26 30 35
  36 41 24 5 -8 2 -23 -7 -36z m629 -18 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10
  -3 6 -2 10 4 10 5 0 13 -4 16 -10z m79 -25 c24 -25 41 -79 30 -96 -11 -18 -23
  -8 -27 24 -4 31 -4 31 -5 -5 -1 -21 -4 -38 -6 -38 -2 0 -11 -3 -20 -6 -11 -4
  -19 2 -26 17 -9 21 -9 20 -4 -8 4 -26 3 -32 -9 -27 -18 6 -37 47 -37 79 0 27
  45 85 66 85 8 0 25 -11 38 -25z m-739 -32 c-8 -45 -18 -58 -40 -50 -28 11 0
  87 32 87 12 0 14 -8 8 -37z m638 22 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9
  16 15 13 8 -4z m-136 -47 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
  m-511 -64 c-3 -7 0 -15 7 -17 19 -7 -7 -30 -29 -25 -24 4 -30 28 -7 29 16 1
  16 2 1 6 -29 7 -22 31 8 25 15 -3 23 -10 20 -18z m77 -61 l28 -52 -46 -45
  c-55 -55 -60 -49 -46 60 5 43 7 82 4 87 -3 5 3 7 13 5 11 -2 31 -26 47 -55z
  m-138 27 c10 -11 29 -20 42 -20 26 0 29 -20 14 -98 -5 -23 -5 -45 0 -48 16 -9
  10 -20 -18 -32 -22 -9 -23 -11 -5 -8 12 2 22 0 22 -5 0 -5 -9 -9 -19 -9 -11 0
  -23 -5 -27 -12 -5 -8 0 -9 14 -6 29 8 28 -10 0 -22 -23 -9 -23 -9 2 -10 l25
  -1 -22 -11 c-13 -6 -23 -15 -23 -21 0 -5 7 -4 15 3 13 11 15 8 14 -16 0 -16
  -3 -23 -6 -16 -4 10 -8 10 -19 1 -22 -18 -61 -3 -67 27 -10 53 28 134 63 134
  18 0 20 6 18 68 -1 56 -2 59 -5 19 -4 -52 -17 -63 -57 -53 -31 8 -39 41 -25
  105 12 57 32 66 64 31z m586 -177 c-21 -42 -91 -98 -113 -91 -7 3 9 20 38 41
  53 38 67 60 81 124 l8 38 3 -37 c2 -22 -5 -52 -17 -75z m-280 77 c10 -6 19
  -17 19 -25 0 -23 -16 -18 -34 10 -18 27 -15 30 15 15z m590 -34 c23 -50 22
  -82 -4 -80 -9 1 -17 -5 -17 -13 0 -8 5 -11 10 -8 15 9 11 -25 -9 -70 l-17 -39
  -27 39 c-15 22 -27 45 -27 51 0 6 -7 17 -15 24 -16 14 -19 29 -7 47 5 8 21 9
  50 3 l44 -8 -7 35 c-3 19 -9 41 -12 49 -3 8 0 14 6 14 6 0 20 -20 32 -44z m29
  25 c0 -5 3 -16 6 -25 3 -9 1 -16 -6 -16 -6 0 -9 4 -6 9 3 5 -1 11 -9 15 -19 7
  -19 26 0 26 8 0 15 -4 15 -9z m-720 -10 c0 -5 -7 -11 -15 -15 -15 -5 -20 5 -8
  17 9 10 23 9 23 -2z m153 -5 c6 -15 -1 -26 -15 -26 -4 0 -8 9 -8 20 0 23 15
  27 23 6z m492 3 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z
  m-528 -99 c4 0 -7 12 -24 26 -18 14 -33 30 -33 35 0 6 17 -4 39 -22 26 -22 37
  -39 33 -51 -9 -35 -56 -12 -92 47 -17 28 -27 34 -44 30 -20 -6 -20 -6 -2 9 18
  15 23 12 67 -29 27 -25 52 -45 56 -45z m498 80 c3 -5 15 -10 26 -10 10 0 19
  -4 19 -10 0 -15 -56 -12 -69 3 -11 13 -6 27 10 27 4 0 11 -4 14 -10z m-275
  -45 c-13 -14 -28 -25 -34 -25 -5 0 3 13 19 30 16 16 31 28 33 25 3 -3 -5 -16
  -18 -30z m-334 -3 c-8 -9 -19 -13 -22 -9 -10 9 16 38 28 31 6 -4 4 -13 -6 -22z
  m29 -23 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z m45 -38
  c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m455 -35 c-51 -45 -75
  -51 -52 -15 6 11 22 22 34 25 12 3 27 12 34 20 6 8 17 14 23 14 6 0 -11 -20
  -39 -44z m-215 4 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
  11 -4 11 -10z m-30 -15 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
  15 6 0 10 -7 10 -15z m-43 -27 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
  0 -25z m-492 -20 c31 -14 33 -38 2 -38 -25 0 -47 17 -47 37 0 16 12 16 45 1z
  m125 -13 c9 -11 10 -19 2 -27 -14 -14 -32 -1 -32 23 0 23 13 25 30 4z m-37
  -32 c23 -61 29 -111 17 -149 -11 -31 -15 -35 -43 -32 -28 3 -36 10 -58 58 -15
  30 -30 70 -34 88 -6 30 -5 32 22 32 20 0 31 -7 38 -22 11 -24 36 -31 42 -11 3
  7 -3 9 -16 6 -21 -5 -29 11 -12 21 5 3 7 15 4 26 -10 37 24 22 40 -17z m130
  -14 c-16 -16 -113 -25 -113 -11 0 15 19 20 75 21 37 1 46 -2 38 -10z m197
  -249 c-6 -11 -13 -20 -16 -20 -2 0 0 9 6 20 6 11 13 20 16 20 2 0 0 -9 -6 -20z"
            />
            <path
                d="M1980 2680 c-20 -6 -14 -8 27 -9 28 -1 60 -3 70 -6 10 -3 5 1 -12 9
  -33 17 -48 18 -85 6z"
            />
            <path
                d="M2486 2534 c-3 -9 -6 -21 -5 -27 0 -7 5 -1 10 12 7 17 15 22 29 18
  11 -3 18 -1 14 4 -9 15 -41 10 -48 -7z"
            />
            <path
                d="M1995 2843 c-16 -3 -35 -9 -42 -15 -7 -5 -16 -7 -21 -4 -5 3 -17 -3
  -27 -14 -20 -22 -15 -25 15 -10 11 6 29 15 40 20 11 6 29 10 39 10 11 0 23 5
  26 10 3 6 5 9 3 9 -2 -1 -16 -4 -33 -6z"
            />
            <path
                d="M2162 2838 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
  -8z"
            />
            <path
                d="M2102 2824 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
  -6z"
            />
            <path
                d="M2017 2580 c34 -4 69 -13 78 -20 8 -7 15 -8 15 -2 0 16 -58 32 -109
  31 -43 -2 -42 -2 16 -9z"
            />
            <path d="M2131 2564 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path
                d="M1835 2539 c-11 -17 -2 -18 19 -3 17 12 17 14 4 14 -9 0 -20 -5 -23
  -11z"
            />
            <path
                d="M2060 2537 c14 -8 27 -12 30 -10 7 6 -23 23 -40 23 -8 0 -4 -6 10
  -13z"
            />
            <path
                d="M1893 2520 c10 -14 20 -33 24 -42 3 -10 9 -18 14 -18 13 0 -16 53
  -38 71 -17 13 -17 13 0 -11z"
            />
            <path
                d="M2147 2528 c2 -5 13 -8 24 -8 10 0 19 3 19 8 0 4 -11 7 -24 7 -13 0
  -22 -3 -19 -7z"
            />
            <path
                d="M1995 2493 c-17 -13 -17 -15 -2 -10 11 4 20 1 23 -8 7 -17 40 -20 49
  -5 9 14 -13 40 -34 40 -9 0 -25 -8 -36 -17z m50 -13 c3 -5 1 -10 -4 -10 -6 0
  -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"
            />
            <path d="M2302 2410 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path
                d="M2611 2425 c0 -5 6 -19 14 -29 8 -11 15 -15 15 -10 0 6 -7 19 -15 30
  -8 10 -14 15 -14 9z"
            />
            <path d="M2188 2413 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path
                d="M2507 2379 c-10 -11 -22 -17 -27 -14 -19 12 -10 -12 13 -33 l22 -21
  12 25 c10 22 10 24 -3 13 -25 -21 -29 0 -4 26 13 14 19 25 14 24 -5 0 -17 -9
  -27 -20z"
            />
            <path d="M2188 2363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path
                d="M2080 2010 c13 -8 11 -12 -10 -27 -13 -10 -31 -21 -40 -25 -13 -6
  -13 -7 1 -7 9 -1 28 8 43 20 14 11 30 17 36 14 5 -3 10 -2 10 4 0 6 -9 15 -19
  21 -25 13 -41 13 -21 0z"
            />
            <path
                d="M2093 1946 c-5 -8 -24 -22 -43 -30 -21 -9 -28 -15 -17 -16 10 0 27 5
  38 10 15 8 23 7 35 -5 8 -8 27 -14 42 -14 l27 2 -28 4 c-28 4 -53 36 -42 53 3
  6 4 10 1 10 -3 0 -9 -7 -13 -14z"
            />
            <path
                d="M1950 1887 c0 -42 -1 -43 -29 -39 -16 2 -37 -1 -48 -6 -14 -8 -8 -9
  24 -5 39 5 43 3 53 -21 13 -35 13 -36 -4 -36 -8 0 -16 -7 -20 -15 -3 -8 -12
  -15 -19 -15 -8 0 -20 -6 -27 -14 -14 -18 -37 -10 -46 16 -3 11 -8 17 -11 14
  -3 -3 3 -18 13 -33 11 -17 17 -42 15 -64 -1 -24 3 -40 15 -50 16 -13 16 -12 6
  8 -16 30 -15 68 3 83 13 11 15 5 15 -41 -1 -32 -7 -62 -15 -73 -28 -37 -10
  -136 25 -145 59 -16 213 33 239 75 10 15 11 9 6 -34 -9 -82 -20 -101 -71 -123
  -25 -11 -60 -23 -78 -26 -40 -6 -93 19 -106 50 -8 21 -9 21 -9 -5 -1 -21 7
  -30 42 -47 45 -22 81 -19 152 14 17 8 37 16 45 18 19 6 50 130 50 198 0 25 6
  54 14 64 14 19 15 19 35 1 18 -16 21 -32 22 -110 1 -50 4 -79 6 -64 l4 28 49
  -26 c27 -14 69 -29 93 -32 42 -7 43 -8 36 -37 -7 -24 -17 -33 -48 -42 -52 -17
  -69 -15 -106 6 -19 11 -32 28 -35 46 -7 40 -43 -129 -38 -180 3 -34 0 -39 -25
  -47 -48 -17 -59 -5 -55 57 1 30 0 55 -4 55 -5 0 -8 -4 -8 -10 0 -5 -16 -28
  -36 -50 -37 -41 -37 -54 0 -22 20 16 20 16 6 -3 -77 -101 -209 -119 -210 -28
  l0 23 33 -20 c37 -23 60 -24 102 -6 28 12 27 13 -26 8 -48 -4 -60 -1 -78 17
  -24 24 -29 81 -7 81 8 0 18 -5 21 -10 3 -5 19 -10 34 -10 33 0 119 28 127 41
  3 6 -11 3 -31 -5 -48 -20 -114 -20 -143 0 -18 12 -23 13 -26 2 -10 -31 -19
  -96 -19 -129 1 -32 -4 -40 -45 -65 -41 -26 -55 -29 -161 -34 l-116 -6 117 -2
  c111 -2 117 -3 124 -24 3 -12 13 -28 20 -36 22 -22 16 -31 -23 -35 -22 -2 -7
  -4 33 -5 53 -2 75 2 84 13 19 23 106 45 177 45 35 0 63 4 63 9 0 4 -22 6 -49
  3 l-49 -4 5 43 c3 24 11 47 17 51 7 5 3 8 -9 8 -16 0 -12 7 20 41 l40 40 5
  -28 c6 -34 43 -42 86 -20 21 11 34 12 56 3 38 -14 31 -28 -21 -43 -35 -10 -49
  -10 -76 2 -26 10 -33 10 -29 1 8 -21 67 -37 102 -27 30 9 32 8 40 -22 10 -36
  3 -61 -9 -31 -7 17 -8 16 -8 -6 -1 -20 -3 -22 -11 -10 -8 13 -10 12 -10 -2 0
  -10 -4 -18 -10 -18 -5 0 -10 8 -11 18 0 12 -3 10 -11 -8 l-11 -25 -6 25 -7 25
  -6 -24 c-5 -16 -12 -22 -22 -18 -10 4 -16 1 -16 -8 0 -12 9 -14 43 -9 23 4 54
  13 69 21 15 7 38 12 50 10 13 -2 18 -2 12 0 -6 2 -15 24 -21 48 -12 52 -5 55
  67 30 23 -8 50 -15 59 -14 9 0 -12 10 -47 23 -35 12 -63 25 -63 29 1 4 1 15 1
  25 0 11 11 24 25 30 14 7 30 24 36 39 6 16 31 47 56 70 43 40 45 44 30 61 -15
  17 -15 20 4 52 11 19 35 49 53 65 27 24 35 40 39 78 4 46 4 47 -11 25 -18 -25
  -40 -44 -86 -71 l-31 -18 30 6 c16 4 35 15 43 26 13 18 42 27 42 12 -1 -14
  -29 -46 -57 -65 -16 -10 -46 -18 -68 -18 -59 0 -172 57 -152 76 3 3 20 -4 38
  -15 19 -12 45 -21 59 -20 22 0 21 2 -9 13 -37 13 -46 25 -56 69 -3 17 -12 27
  -23 27 -11 0 -5 6 15 18 17 9 43 25 57 36 15 10 29 16 32 14 3 -3 -14 -20 -38
  -37 -23 -17 -36 -31 -29 -31 9 0 12 -9 9 -25 l-5 -24 26 18 c14 11 35 28 46
  39 12 10 27 22 35 25 12 4 11 6 -2 6 -10 1 -22 9 -27 20 -8 14 -4 24 20 47 17
  16 35 37 41 47 5 10 -10 0 -33 -23 -48 -47 -147 -115 -155 -107 -3 2 17 21 43
  40 46 34 133 117 122 117 -3 0 -29 -23 -59 -50 -65 -60 -117 -93 -125 -79 -10
  15 -57 11 -76 -7 -15 -13 -24 -14 -53 -4 -19 6 -38 20 -41 30 -4 11 -12 17
  -21 13 -17 -6 -19 -64 -2 -81 9 -9 9 -12 0 -12 -7 0 -12 5 -12 10 0 7 -6 7
  -19 0 -27 -14 -38 6 -17 29 23 25 20 38 -4 16 -11 -10 -20 -26 -20 -37 0 -20
  -21 -23 -51 -8 -21 12 -25 40 -7 58 9 9 9 12 0 12 -15 0 -16 46 -2 55 6 4 13
  -7 16 -24 6 -27 8 -29 30 -19 17 8 27 8 38 -1 11 -9 15 -8 20 4 3 9 10 13 16
  10 5 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3 -10 16 -10 27 0 11 -11 31 -25 44
  -36 33 -33 91 5 108 8 3 3 5 -12 3 -18 -3 -25 0 -21 9 2 6 0 15 -6 19 -7 4
  -11 -9 -11 -38z m10 -164 c0 -29 -17 -38 -25 -13 -9 28 4 68 16 49 5 -8 9 -24
  9 -36z m28 9 c4 -44 -15 -41 -20 3 -2 22 0 34 6 32 6 -2 12 -18 14 -35z m19
  -19 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m373 -77 c0 -8 -4
  -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-278 -12
  c68 -23 75 -55 9 -37 -23 6 -23 7 -5 14 16 7 15 8 -7 8 -15 1 -32 8 -39 16
  -15 19 -14 19 42 -1z m248 -20 c0 -14 -21 -19 -33 -7 -9 9 13 34 24 27 5 -3 9
  -12 9 -20z m-443 -96 c26 -28 116 -24 170 7 23 14 43 32 43 40 0 8 5 15 10 15
  40 0 -44 -75 -102 -91 -112 -31 -165 -4 -150 76 l7 40 3 -35 c2 -19 11 -43 19
  -52z m160 42 c6 0 14 7 17 16 3 8 11 12 17 9 7 -5 4 -13 -9 -25 -71 -66 -190
  -66 -180 1 3 21 8 24 36 21 18 -1 41 2 50 7 12 7 23 4 38 -10 11 -10 25 -19
  31 -19z m211 11 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15
  -10 17 -17z m-6 -221 c39 -24 73 -25 122 -5 32 13 36 13 36 0 0 -39 -121 -53
  -166 -19 -26 19 -43 44 -30 44 3 0 20 -9 38 -20z m73 -66 c35 9 31 -7 -11 -54
  -41 -45 -86 -54 -104 -21 -11 21 -14 104 -4 113 3 3 25 -5 50 -19 30 -17 53
  -23 69 -19z m-405 -174 c14 0 36 7 48 16 28 19 42 11 42 -26 0 -38 -21 -57
  -85 -75 -29 -9 -59 -22 -65 -30 -15 -18 -54 -20 -47 -2 2 6 9 11 14 10 5 -1
  10 4 10 12 0 11 -5 11 -20 4 -10 -6 -21 -8 -24 -5 -3 3 2 8 11 12 9 3 16 11
  16 17 0 7 -4 7 -12 -1 -7 -7 -19 -12 -27 -12 -11 0 -9 6 9 21 23 19 23 20 3
  14 -36 -10 -21 23 22 50 35 20 42 22 59 9 11 -8 32 -14 46 -14z"
            />
            <path
                d="M2455 1850 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
  -13 -4 -16 -10z"
            />
            <path
                d="M2136 1824 c-23 -23 -20 -30 4 -9 11 10 20 20 20 22 0 7 -9 2 -24
  -13z"
            />
            <path
                d="M2496 1822 c-11 -10 -5 -27 20 -57 20 -24 23 -25 14 -6 -9 17 -9 26
  -1 34 6 6 11 14 11 18 0 9 -37 18 -44 11z"
            />
            <path
                d="M2473 1761 c-7 -10 -9 -29 -6 -41 4 -17 2 -21 -8 -18 -8 3 -13 12
  -11 19 2 8 -10 1 -28 -16 -30 -29 -30 -29 -9 -37 30 -12 72 27 64 59 -3 12 -1
  25 5 28 5 3 10 -2 11 -12 0 -10 3 -13 5 -6 3 7 2 19 -3 27 -7 13 -10 13 -20
  -3z m-37 -88 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z"
            />
            <path
                d="M2170 1710 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
  -4 -4 -4 -10z"
            />
            <path
                d="M2792 1501 c-7 -21 -12 -46 -12 -55 0 -20 -14 -21 -30 0 -8 8 -22 20
  -33 25 -18 10 -19 8 -16 -34 4 -40 2 -44 -13 -36 -9 5 -26 9 -37 9 -17 0 -21
  -6 -21 -33 0 -18 7 -43 16 -55 14 -21 14 -22 -6 -22 -27 0 -90 -26 -90 -38 0
  -28 151 -90 223 -92 4 0 7 -11 7 -25 0 -22 4 -25 35 -25 30 0 35 3 35 25 0 20
  6 25 38 30 73 13 108 25 150 52 23 15 42 30 42 34 0 8 -75 39 -94 39 -11 0
  -12 6 -1 36 21 63 2 89 -47 63 -19 -10 -20 -9 -14 24 9 46 -7 56 -45 28 l-29
  -22 0 26 c0 29 -26 85 -39 85 -4 0 -13 -17 -19 -39z"
            />
            <path
                d="M1427 1495 c-9 -20 -17 -43 -17 -51 0 -15 -2 -15 -25 0 -36 24 -45
  20 -45 -18 0 -34 -1 -35 -35 -29 -34 5 -35 4 -35 -25 0 -17 4 -42 9 -55 9 -23
  7 -25 -28 -31 -51 -8 -71 -31 -45 -52 38 -29 77 -46 138 -58 55 -11 61 -15 64
  -39 3 -24 8 -27 43 -27 35 0 39 3 39 25 0 25 17 33 111 52 35 6 109 50 109 63
  0 15 -29 31 -64 37 -37 6 -43 12 -27 28 10 10 15 46 13 77 -2 14 -7 15 -34 7
  -18 -5 -34 -9 -35 -9 -1 0 1 15 4 34 7 45 -9 52 -46 20 -24 -20 -31 -22 -31
  -10 0 21 -30 96 -39 96 -4 0 -15 -16 -24 -35z"
            />
            <path
                d="M2105 1328 c-16 -17 -17 -20 -3 -15 9 3 19 1 22 -6 2 -7 5 0 5 16 1
  32 2 32 -24 5z"
            />
            <path
                d="M1302 1120 c26 -5 71 -16 99 -25 27 -9 53 -15 56 -12 9 9 -127 47
  -166 46 -34 -1 -33 -2 11 -9z"
            />
            <path
                d="M2871 1107 c-36 -11 -59 -23 -53 -25 7 -2 34 4 62 13 28 9 72 20 98
  25 l47 8 -45 0 c-25 0 -74 -9 -109 -21z"
            />
            <path d="M1488 1073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M2438 1073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M2778 1073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path
                d="M2492 1065 c7 -7 239 -8 246 -1 3 3 -52 6 -123 6 -71 0 -126 -2 -123
  -5z"
            />
            <path d="M1348 1013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M2918 1013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path
                d="M1390 1005 c0 -10 136 -45 174 -44 39 1 39 2 -14 10 -30 4 -75 15
  -99 23 -45 16 -61 19 -61 11z"
            />
            <path
                d="M2385 989 c27 -11 72 -22 100 -25 l50 -4 -60 14 c-33 8 -73 19 -88
  25 -16 6 -34 11 -40 10 -7 0 10 -9 38 -20z"
            />
            <path
                d="M2825 994 c-22 -8 -62 -19 -90 -23 l-50 -9 41 -1 c22 0 72 10 110 24
  38 13 60 24 49 24 -11 0 -38 -7 -60 -15z"
            />
            <path
                d="M1991 925 c-66 -15 -102 -33 -41 -21 141 29 280 31 346 6 14 -6 35
  -10 47 -9 15 0 10 5 -18 15 -58 22 -254 27 -334 9z"
            />
            <path
                d="M1468 880 c13 -5 62 -15 111 -20 89 -11 211 -2 276 20 26 9 24 9 -15
  4 -151 -21 -215 -21 -355 1 -32 4 -36 4 -17 -5z"
            />
            <path
                d="M2445 874 c68 -21 271 -21 340 0 l50 16 -45 -6 c-147 -20 -206 -20
  -350 0 l-45 6 50 -16z"
            />
            <path d="M2113 833 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z" />
            <path
                d="M1340 815 c30 -7 70 -18 87 -25 18 -6 38 -10 44 -8 18 6 -123 48
  -156 47 -23 -1 -16 -4 25 -14z"
            />
            <path
                d="M1911 808 c-35 -12 -59 -24 -52 -26 6 -2 33 3 59 12 26 9 63 20 82
  25 35 9 35 9 5 10 -16 0 -59 -10 -94 -21z"
            />
            <path
                d="M2279 819 c24 -5 63 -16 87 -25 23 -9 48 -14 54 -12 19 6 -121 48
  -155 47 -25 -1 -22 -3 14 -10z"
            />
            <path
                d="M2860 807 c-45 -15 -57 -23 -42 -25 13 -2 29 1 36 7 7 6 39 17 72 25
  40 10 49 14 29 15 -16 0 -59 -10 -95 -22z"
            />
            <path d="M1498 773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M1808 773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M2448 773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M2758 773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M1618 763 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z" />
            <path d="M2563 763 c32 -2 81 -2 110 0 29 2 3 3 -58 3 -60 0 -84 -1 -52 -3z" />
            <path
                d="M2025 731 c-22 -3 30 -6 115 -6 97 -1 138 2 110 6 -53 9 -169 9 -225
  0z"
            />
            <path d="M1368 713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M1948 713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path
                d="M1400 705 c0 -12 139 -45 189 -44 l56 2 -65 7 c-36 5 -85 15 -110 24
  -45 16 -70 20 -70 11z"
            />
            <path
                d="M1855 693 c-22 -8 -69 -18 -105 -23 -65 -7 -65 -7 -15 -9 48 -1 121
  15 175 37 40 17 -7 13 -55 -5z"
            />
            <path
                d="M2361 700 c32 -18 134 -40 184 -39 48 1 47 2 -20 10 -38 5 -90 16
  -114 24 -50 17 -74 19 -50 5z"
            />
            <path
                d="M2817 695 c-21 -8 -70 -19 -110 -24 l-72 -10 75 4 c45 2 95 11 125
  24 56 22 41 27 -18 6z"
            />
            <path
                d="M1957 621 c-38 -10 -71 -22 -75 -26 -8 -7 38 2 83 17 48 16 234 22
  295 9 110 -22 119 -23 75 -5 -64 27 -290 29 -378 5z"
            />
            <path
                d="M1485 578 c40 -15 320 -15 360 0 26 10 22 11 -25 4 -71 -9 -228 -9
  -305 0 -51 7 -55 6 -30 -4z"
            />
            <path
                d="M2440 578 c35 -17 316 -17 350 0 22 11 18 11 -25 5 -64 -10 -235 -10
  -300 -1 -43 7 -46 6 -25 -4z"
            />
            <path d="M2098 533 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z" />
            <path
                d="M1890 505 c-36 -13 -55 -24 -43 -25 12 0 44 9 71 19 26 10 59 21 72
  24 22 4 21 4 -5 5 -16 0 -59 -10 -95 -23z"
            />
            <path
                d="M2310 516 c25 -7 58 -19 73 -25 16 -6 36 -11 45 -11 27 1 -79 40
  -123 45 l-40 5 45 -14z"
            />
            <path d="M1788 473 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M2468 473 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M2078 433 c34 -2 88 -2 120 0 31 2 3 3 -63 3 -66 0 -92 -1 -57 -3z" />
            <path d="M1968 423 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M2288 423 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path
                d="M19552 4073 c-16 -6 -72 -116 -72 -140 1 -25 24 16 45 80 9 26 23 48
  31 48 27 3 32 4 40 12 8 8 -24 8 -44 0z"
            />
            <path
                d="M19625 4070 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
  -12 -4 -9 -10z"
            />
            <path
                d="M19840 4066 c0 -5 8 -23 19 -40 10 -17 22 -42 26 -56 7 -25 23 -63
  56 -136 11 -22 19 -45 19 -51 0 -15 49 -111 50 -97 0 6 -21 60 -46 120 -25 60
  -56 138 -70 173 -21 57 -54 110 -54 87z"
            />
            <path d="M19466 3895 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path
                d="M19437 3825 c-24 -50 -20 -58 4 -12 11 22 19 41 17 43 -3 2 -12 -12
  -21 -31z"
            />
            <path
                d="M19662 3722 c-10 -10 -31 -50 -45 -88 -68 -182 -87 -234 -87 -243 0
  -6 -11 -34 -24 -63 -13 -29 -29 -69 -34 -88 -6 -19 -18 -55 -27 -79 -36 -98
  -4 -110 270 -104 122 3 215 9 223 15 15 13 16 82 1 110 -12 23 -59 148 -94
  248 -12 36 -31 85 -43 110 -11 25 -23 61 -27 80 -8 42 -55 114 -77 118 -9 2
  -25 -5 -36 -16z m49 -113 c18 -36 45 -100 69 -169 15 -41 46 -126 70 -189 35
  -92 40 -115 29 -122 -18 -11 -350 -11 -367 0 -11 7 -9 24 14 92 15 46 30 89
  35 94 4 6 17 42 30 80 12 39 33 93 46 120 12 28 23 59 23 71 0 39 35 55 51 23z"
            />
            <path
                d="M20021 3640 c0 -8 8 -31 19 -50 25 -46 25 -27 0 25 -11 22 -19 33
  -19 25z"
            />
            <path
                d="M12105 3513 c-108 -20 -157 -48 -208 -122 -28 -41 -32 -54 -32 -116
  0 -114 40 -172 185 -266 41 -27 90 -59 108 -71 62 -42 119 -103 133 -140 41
  -116 -112 -225 -229 -164 -39 20 -71 83 -72 139 l0 38 -67 -3 -68 -3 4 -65 c4
  -74 15 -101 64 -150 87 -91 316 -91 424 -2 52 44 89 145 80 217 -14 96 -53
  140 -237 260 -131 87 -200 163 -200 222 0 66 79 133 156 133 46 0 119 -41 128
  -72 3 -13 6 -35 6 -50 0 -53 7 -58 77 -58 l66 0 -7 50 c-16 117 -61 178 -151
  204 -54 16 -128 25 -160 19z"
            />
            <path
                d="M12799 3509 c-82 -12 -130 -42 -185 -115 -25 -32 -28 -45 -29 -113 0
  -42 3 -88 7 -102 11 -36 95 -119 175 -172 69 -44 107 -69 148 -97 13 -8 39
  -35 59 -59 29 -35 36 -51 36 -87 0 -51 -23 -104 -45 -104 -8 0 -15 -4 -15 -9
  0 -5 -12 -13 -27 -19 -101 -40 -191 2 -212 99 -6 27 -10 55 -8 62 1 8 -19 13
  -65 15 l-68 3 0 -35 c0 -175 111 -268 303 -253 106 8 146 23 198 76 37 36 49
  57 58 99 27 127 10 183 -78 263 -36 32 -69 59 -73 59 -16 0 -176 118 -215 159
  -54 56 -67 99 -47 150 36 91 173 120 244 51 30 -29 39 -52 43 -105 2 -30 3
  -30 66 -33 l64 -3 -6 65 c-3 36 -13 78 -21 93 -19 38 -65 79 -101 93 -43 17
  -153 27 -206 19z"
            />
            <path
                d="M19317 3513 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
  -9 2 -12 -2z"
            />
            <path
                d="M13835 3503 c-98 -22 -153 -70 -187 -163 -22 -62 -23 -77 -23 -330 0
  -258 1 -266 24 -320 36 -82 83 -127 159 -150 77 -24 138 -25 217 -5 118 31
  177 107 194 250 23 189 8 511 -27 584 -25 54 -95 113 -149 128 -46 12 -167 16
  -208 6z m160 -108 c48 -19 81 -65 96 -136 7 -30 10 -138 7 -277 -3 -220 -4
  -228 -28 -271 -33 -59 -77 -84 -147 -84 -44 0 -62 6 -96 29 -67 48 -80 102
  -81 354 -1 186 1 212 22 277 24 77 26 79 87 105 51 21 89 22 140 3z"
            />
            <path
                d="M15590 3498 c-55 -16 -99 -50 -140 -108 -22 -31 -25 -46 -25 -120 0
  -70 4 -91 22 -121 25 -39 90 -96 173 -150 65 -42 104 -68 135 -89 13 -8 39
  -35 59 -59 29 -35 36 -51 36 -86 0 -57 -14 -85 -55 -114 -111 -75 -223 -31
  -247 97 l-12 62 -63 0 -63 0 0 -35 c0 -74 21 -137 62 -180 33 -37 50 -46 109
  -60 188 -46 358 26 388 164 27 125 9 183 -77 260 -35 31 -69 58 -75 61 -36 15
  -176 119 -213 158 -58 60 -72 106 -49 155 40 84 170 114 237 54 32 -28 44 -51
  53 -104 l7 -43 60 0 61 0 -6 65 c-14 143 -96 206 -266 204 -42 0 -92 -5 -111
  -11z"
            />
            <path
                d="M4670 3468 c4 -18 19 -71 33 -118 14 -47 40 -137 58 -200 17 -63 45
  -157 60 -209 16 -52 29 -100 29 -108 0 -7 14 -55 30 -105 17 -50 30 -96 30
  -102 0 -6 5 -28 11 -48 l10 -38 77 0 77 0 28 98 c16 53 40 134 53 179 26 87
  67 236 89 320 7 28 23 81 35 118 12 37 28 92 35 123 7 32 17 72 23 90 l11 32
  -69 0 -68 0 -7 -42 c-4 -24 -20 -89 -36 -144 -16 -56 -29 -108 -29 -115 0 -8
  -14 -66 -31 -129 -77 -292 -89 -338 -95 -378 -3 -24 -9 -40 -13 -35 -4 4 -18
  53 -31 108 -12 55 -33 138 -45 185 -13 47 -26 103 -30 125 -4 22 -20 90 -35
  150 -15 61 -37 146 -47 190 l-19 80 -71 3 -71 3 8 -33z"
            />
            <path d="M5450 3020 l0 -480 65 0 65 0 0 480 0 480 -65 0 -65 0 0 -480z" />
            <path
                d="M5770 3020 l0 -480 56 0 55 0 -3 424 c-2 233 -1 423 2 421 3 -2 17
  -28 29 -57 13 -29 39 -86 57 -127 19 -40 34 -77 34 -81 0 -5 14 -38 30 -73 17
  -36 30 -68 30 -71 0 -3 14 -35 30 -71 17 -36 30 -69 30 -74 0 -5 14 -36 30
  -69 17 -34 30 -65 30 -69 0 -4 15 -41 32 -80 l33 -73 82 0 82 0 3 28 c2 15 2
  231 0 480 l-2 452 -55 0 -55 0 0 -422 c-1 -367 -3 -418 -14 -388 -30 77 -77
  186 -87 204 -6 10 -19 42 -29 70 -22 65 -56 145 -92 220 -15 33 -28 65 -28 72
  0 8 -13 42 -29 76 -16 35 -38 86 -50 113 l-20 50 -90 3 -91 3 0 -481z"
            />
            <path
                d="M6813 3413 c-16 -48 -44 -135 -63 -193 -18 -58 -45 -143 -60 -190
  -15 -47 -42 -132 -59 -190 -18 -58 -46 -144 -62 -192 -16 -48 -29 -92 -29 -98
  0 -7 23 -10 67 -8 l66 3 18 55 c9 30 22 81 29 113 l13 57 179 0 c157 0 178 -2
  178 -16 0 -9 14 -61 31 -115 l32 -99 64 0 c57 0 64 2 59 18 -3 9 -8 31 -12 47
  -3 17 -19 68 -34 115 -15 47 -42 135 -60 195 -18 61 -46 153 -62 205 -46 146
  -87 280 -101 330 l-14 45 -75 3 -75 3 -30 -88z m131 -165 c33 -107 79 -265 94
  -320 6 -21 7 -40 4 -42 -14 -8 -261 -6 -266 2 -3 5 7 49 23 98 51 158 90 288
  96 322 4 17 10 32 14 32 4 0 20 -42 35 -92z"
            />
            <path
                d="M7260 3493 c0 -4 30 -72 66 -152 67 -148 84 -185 148 -322 l36 -77 0
  -201 0 -201 60 0 60 0 0 189 0 189 46 98 c25 55 65 140 89 189 80 167 135 285
  135 290 0 3 -26 5 -59 5 -65 0 -72 -5 -91 -65 -7 -21 -24 -60 -36 -86 -13 -26
  -24 -50 -24 -54 0 -4 -15 -42 -33 -84 -19 -42 -42 -96 -52 -119 -17 -40 -20
  -42 -32 -26 -7 10 -13 24 -13 31 0 7 -11 36 -24 65 -14 29 -41 96 -61 148 -20
  52 -46 116 -57 143 l-21 47 -69 0 c-37 0 -68 -3 -68 -7z"
            />
            <path
                d="M8170 3483 c0 -18 -37 -135 -91 -289 -16 -45 -29 -86 -29 -92 0 -6
  -13 -51 -29 -99 -16 -49 -43 -135 -61 -193 -18 -58 -45 -142 -60 -188 l-29
  -83 71 3 70 3 14 40 c7 22 14 47 14 55 0 7 8 40 17 72 l17 58 173 0 172 0 31
  -95 c16 -52 30 -104 30 -115 0 -18 7 -20 71 -20 64 0 70 2 64 18 -3 9 -13 44
  -22 77 -8 33 -23 85 -33 115 -10 30 -34 109 -54 175 -20 66 -50 163 -66 215
  -57 181 -88 285 -97 320 l-9 35 -82 3 c-73 2 -82 1 -82 -15z m109 -220 c13
  -43 35 -121 51 -173 15 -52 34 -118 43 -147 8 -28 12 -54 9 -57 -8 -9 -272 -7
  -272 1 0 4 11 43 25 87 79 255 94 304 100 334 4 17 10 32 14 32 4 0 18 -35 30
  -77z"
            />
            <path
                d="M8740 3020 l0 -481 61 3 61 3 -1 237 c-1 146 2 239 8 242 5 4 22 -17
  36 -46 15 -29 32 -60 39 -68 7 -8 18 -26 24 -40 7 -14 30 -52 52 -85 22 -33
  46 -71 53 -85 7 -14 25 -44 40 -67 15 -24 27 -46 27 -51 0 -5 8 -16 19 -26 15
  -14 38 -16 151 -14 l132 3 23 74 c13 40 27 91 30 112 l7 39 178 0 179 0 7 -37
  c3 -21 17 -73 31 -115 l24 -78 64 0 65 0 -7 33 c-3 17 -18 70 -33 117 -15 47
  -41 132 -59 190 -17 58 -45 148 -61 200 -40 129 -84 271 -107 350 l-19 65 -76
  3 -76 3 -25 -73 c-13 -40 -41 -127 -62 -193 -44 -141 -78 -248 -115 -365 -60
  -192 -93 -296 -97 -309 -4 -16 -63 65 -63 87 0 6 -4 12 -8 12 -4 0 -25 28 -45
  63 -20 34 -55 89 -77 122 -21 33 -46 74 -54 90 -9 17 -30 52 -47 79 -29 47
  -30 50 -14 80 9 17 36 58 61 91 25 33 56 76 69 95 13 19 37 53 53 75 15 22 45
  64 65 93 l37 52 -70 0 c-62 0 -71 -2 -81 -22 -6 -13 -21 -33 -32 -45 -11 -12
  -25 -31 -31 -41 -6 -11 -31 -48 -56 -82 -25 -35 -52 -73 -60 -85 -56 -83 -90
  -129 -91 -122 -1 4 -3 12 -4 17 -1 6 -3 93 -4 195 l-1 185 -60 0 -60 0 0 -480z
  m1040 10 c16 -52 30 -106 32 -120 l3 -25 -132 -3 -133 -3 0 24 c0 13 11 57 25
  98 14 41 30 94 36 119 5 25 24 85 42 133 l32 88 33 -108 c18 -59 46 -151 62
  -203z"
            />
            <path
                d="M10500 3020 l0 -480 60 0 60 0 1 443 c1 490 -5 465 60 252 66 -217
  93 -303 119 -385 15 -47 43 -136 61 -198 l34 -112 54 2 54 3 32 95 c17 52 46
  142 64 200 37 124 79 259 119 390 16 52 36 115 43 140 12 40 14 -5 17 -392 l2
  -438 55 0 55 0 0 480 0 481 -96 -3 -96 -3 -29 -90 c-16 -49 -43 -137 -60 -195
  -18 -58 -47 -150 -65 -205 -18 -55 -45 -148 -61 -207 -25 -97 -42 -121 -43
  -59 0 13 -13 63 -28 110 -31 92 -73 225 -122 386 -45 144 -62 196 -77 233
  l-13 32 -100 0 -100 0 0 -480z"
            />
            <path d="M11580 3020 l0 -480 65 0 65 0 0 480 0 480 -65 0 -65 0 0 -480z" />
            <path
                d="M13320 3020 l0 -480 59 0 c48 0 60 3 63 17 2 9 2 225 0 480 l-2 463
  -60 0 -60 0 0 -480z"
            />
            <path
                d="M14400 3020 l0 -481 55 3 56 3 0 425 0 425 24 -45 c13 -25 32 -67 41
  -95 9 -27 23 -59 30 -70 7 -11 22 -45 33 -75 11 -30 30 -73 41 -95 11 -21 20
  -45 20 -51 0 -7 14 -39 30 -72 17 -34 30 -65 30 -70 0 -5 14 -36 30 -70 17
  -34 30 -66 30 -71 0 -5 14 -39 31 -75 l31 -66 84 0 84 0 0 480 0 480 -55 0
  -55 0 0 -422 0 -422 -35 73 c-19 40 -35 78 -35 84 0 7 -14 39 -30 72 -16 33
  -30 66 -30 73 0 7 -13 39 -30 72 -16 33 -30 64 -30 69 0 5 -13 39 -30 74 -16
  36 -30 69 -30 73 0 4 -13 36 -30 71 -16 34 -30 68 -30 73 0 6 -11 33 -25 60
  l-25 50 -90 0 -90 0 0 -480z"
            />
            <path
                d="M15220 3470 c-7 -4 -10 -67 -8 -180 l3 -174 35 0 35 0 3 176 2 176
  -30 4 c-16 2 -34 2 -40 -2z"
            />
            <path
                d="M19374 2849 c-36 -8 -43 -15 -62 -57 -12 -26 -28 -65 -36 -87 -45
  -130 -70 -192 -84 -207 -21 -25 3 -24 43 1 31 18 32 21 111 246 15 44 35 78
  52 92 15 13 25 22 22 22 -3 -1 -23 -5 -46 -10z"
            />
            <path
                d="M19981 2848 c25 -10 70 -98 100 -193 6 -22 18 -49 24 -60 7 -11 18
  -36 24 -56 8 -26 20 -38 44 -47 45 -16 66 -16 45 1 -14 10 -78 150 -78 169 0
  4 -13 36 -29 70 -15 35 -31 73 -35 85 -7 23 -18 27 -86 36 -32 5 -33 4 -9 -5z"
            />
            <path d="M19026 2785 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path
                d="M20400 2715 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
  -5 8 -10 11 -10 5z"
            />
            <path d="M18967 2631 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z" />
            <path
                d="M20448 2588 c6 -26 42 -88 42 -74 0 11 -40 96 -45 96 -2 0 -1 -10 3
  -22z"
            />
            <path
                d="M18942 2575 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
  -21z"
            />
            <path
                d="M19346 2488 c-23 -33 -22 -139 2 -168 15 -19 24 -22 48 -15 16 5 34
  7 40 7 6 -1 10 16 10 45 -1 45 -3 48 -28 51 -16 2 -28 -1 -28 -7 0 -6 9 -11
  21 -11 18 0 20 -4 14 -27 -10 -41 -31 -60 -53 -47 -21 14 -30 147 -11 170 17
  21 44 17 62 -8 12 -17 16 -19 16 -7 2 42 -67 54 -93 17z"
            />
            <path
                d="M19497 2503 c-11 -11 -8 -193 3 -193 6 0 10 20 10 45 0 40 2 45 23
  45 32 0 47 -18 47 -57 0 -18 5 -33 11 -33 5 0 7 5 4 10 -4 6 -7 48 -7 94 1 78
  -1 84 -21 90 -30 8 -62 7 -70 -1z m64 -13 c23 -13 25 -55 3 -64 -39 -15 -54
  -7 -54 27 0 48 13 57 51 37z"
            />
            <path
                d="M19670 2466 c-7 -24 -10 -50 -7 -58 2 -7 8 4 12 25 4 20 11 37 16 37
  7 0 29 -62 29 -84 0 -4 -15 -6 -34 -4 -33 4 -35 2 -44 -34 -6 -21 -6 -38 -2
  -38 4 0 10 14 14 30 5 26 10 30 36 30 25 0 33 -5 41 -30 20 -55 28 -31 10 28
  -10 31 -23 75 -27 96 -12 54 -29 55 -44 2z"
            />
            <path
                d="M19790 2410 l0 -100 44 0 c59 0 70 16 70 102 0 81 -13 98 -76 98
  l-38 0 0 -100z m88 75 c10 -9 21 -89 16 -113 -10 -40 -23 -52 -55 -52 l-29 0
  0 84 c0 88 6 99 45 89 11 -3 22 -7 23 -8z"
            />
            <path
                d="M19957 2503 c-3 -5 -6 -50 -6 -100 -1 -90 0 -93 21 -92 13 0 17 3 11
  6 -8 2 -13 22 -13 44 0 40 4 43 48 41 12 -1 22 3 22 8 0 6 -16 10 -35 10 -34
  0 -35 1 -35 39 0 40 0 40 43 44 l42 4 -47 1 c-26 1 -49 -1 -51 -5z"
            />
            <path d="M18978 2483 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z" />
            <path
                d="M18789 2163 c0 -27 -1 -70 -2 -98 l-2 -50 -248 -3 -248 -2 28 -52
  c15 -29 34 -59 41 -66 7 -8 22 -33 34 -56 11 -22 24 -43 28 -46 4 -3 12 -15
  19 -28 15 -28 0 -80 -29 -107 -11 -10 -20 -22 -20 -27 0 -5 -16 -33 -35 -62
  -19 -29 -35 -58 -35 -63 0 -6 -7 -16 -15 -23 -8 -7 -15 -16 -15 -21 0 -5 141
  -9 315 -9 l315 0 0 105 0 105 815 0 814 0 3 -102 3 -103 313 -3 c171 -1 312 0
  312 3 0 10 -38 77 -80 140 -21 33 -48 77 -59 98 l-20 38 26 47 c14 26 32 54
  39 63 8 8 14 19 14 24 0 4 18 34 40 67 22 33 40 64 40 69 0 5 -112 9 -251 9
  l-251 0 7 100 8 100 -952 0 -951 0 -1 -47z"
            />
            <path
                d="M6168 2151 c-70 -28 -101 -54 -133 -113 -50 -93 -34 -211 39 -280 37
  -34 43 -39 146 -108 127 -85 176 -126 200 -165 58 -94 -18 -208 -139 -208 -87
  0 -137 48 -148 142 l-6 51 -64 0 -65 0 4 -72 c4 -88 33 -137 105 -183 47 -29
  52 -30 168 -30 129 1 166 12 226 67 34 32 69 126 69 186 -1 98 -48 160 -199
  261 -46 31 -98 66 -115 77 -43 29 -95 90 -113 132 -35 85 53 180 159 170 71
  -7 114 -51 124 -124 l6 -54 64 0 64 0 0 32 c0 48 -30 133 -59 167 -58 67 -227
  93 -333 52z"
            />
            <path
                d="M9100 2157 c-76 -30 -86 -36 -112 -63 -15 -16 -39 -56 -55 -89 -28
  -60 -28 -62 -31 -293 -4 -249 2 -298 44 -392 45 -101 112 -135 259 -134 108 0
  127 7 191 69 38 36 74 129 74 190 l0 45 -64 0 -63 0 -6 -49 c-8 -66 -30 -116
  -61 -136 -39 -26 -98 -30 -149 -10 -39 15 -48 24 -67 66 -35 83 -42 152 -38
  364 4 186 6 203 29 250 32 66 76 95 144 95 44 0 56 -5 87 -33 30 -28 37 -42
  43 -90 l7 -57 64 0 64 0 0 38 c0 53 -30 132 -64 167 -16 16 -47 38 -70 49 -53
  24 -178 31 -226 13z"
            />
            <path
                d="M11517 2146 c-134 -56 -166 -147 -167 -476 0 -287 28 -381 136 -453
  48 -32 50 -32 169 -32 113 0 122 2 162 27 63 41 91 76 115 148 21 61 23 82 23
  310 0 230 -1 249 -23 316 -28 85 -72 133 -150 163 -76 30 -188 28 -265 -3z
  m240 -113 c45 -36 59 -82 73 -243 11 -130 -1 -316 -24 -394 -20 -66 -48 -93
  -110 -106 -74 -15 -131 5 -173 58 -27 36 -32 52 -38 127 -10 122 -1 453 14
  490 29 71 88 107 169 102 40 -3 61 -11 89 -34z"
            />
            <path
                d="M16322 2147 c-92 -38 -141 -104 -162 -218 -13 -66 -13 -441 -1 -506
  14 -71 55 -154 88 -179 66 -49 95 -58 193 -58 117 -1 159 7 196 39 16 14 32
  25 36 25 4 0 24 30 45 68 l38 67 0 285 0 285 -26 55 c-14 30 -39 69 -56 86
  -73 72 -241 97 -351 51z m199 -91 c66 -28 95 -81 109 -203 22 -196 7 -404 -36
  -490 -24 -46 -33 -54 -68 -64 -23 -5 -47 -12 -54 -14 -24 -8 -107 16 -129 37
  -45 42 -64 119 -70 282 -11 291 25 420 127 459 28 10 88 7 121 -7z"
            />
            <path
                d="M4670 1676 l0 -486 60 0 60 0 0 225 0 225 83 -1 c166 -1 186 -25 194
  -232 3 -73 8 -150 12 -172 l6 -40 64 -3 c59 -3 62 -2 56 18 -3 11 -10 100 -13
  198 -6 151 -10 182 -27 211 -21 36 -32 45 -80 62 l-30 11 46 23 c86 44 121
  135 104 270 -5 47 -13 65 -44 97 -52 56 -107 69 -318 75 l-173 6 0 -487z m367
  355 c55 -41 69 -129 34 -207 -25 -56 -79 -81 -193 -90 l-88 -7 0 168 0 167
  108 -4 c95 -3 111 -6 139 -27z"
            />
            <path
                d="M5400 1675 l0 -485 240 0 240 0 0 55 0 55 -175 0 -175 0 0 170 0 169
  168 0 167 0 3 50 3 51 -170 0 -171 0 0 160 0 160 175 0 175 0 0 50 0 50 -240
  0 -240 0 0 -485z"
            />
            <path
                d="M6750 1675 l0 -485 240 0 240 0 0 55 0 55 -177 2 -178 3 0 165 0 165
  173 3 172 2 0 50 0 50 -172 2 -173 3 0 155 0 155 178 3 177 2 0 50 0 50 -240
  0 -240 0 0 -485z"
            />
            <path
                d="M7611 2142 c-6 -11 -21 -57 -35 -103 -14 -46 -34 -113 -46 -149 -11
  -36 -35 -110 -52 -165 -29 -93 -41 -133 -113 -355 -15 -47 -34 -107 -41 -133
  l-12 -48 65 3 65 3 24 70 c14 39 30 91 35 118 l11 47 176 -2 176 -3 8 -43 c4
  -24 16 -69 28 -101 11 -31 20 -65 20 -74 0 -14 11 -17 69 -17 l68 0 -12 53
  c-7 28 -24 86 -37 127 -14 41 -40 125 -58 185 -18 61 -46 153 -62 205 -17 52
  -44 140 -60 195 -17 55 -38 123 -47 150 l-16 50 -72 3 c-62 2 -73 0 -82 -16z
  m109 -244 c61 -198 89 -293 94 -325 l6 -33 -135 0 -135 0 5 28 c4 15 19 70 35
  122 16 52 44 143 62 202 18 59 34 108 35 108 1 0 16 -46 33 -102z"
            />
            <path
                d="M8170 1676 l0 -486 65 0 65 0 0 225 0 225 85 0 c168 0 183 -20 195
  -260 4 -93 11 -173 15 -179 3 -6 33 -11 67 -11 58 0 60 1 54 23 -4 12 -9 96
  -13 187 -7 203 -18 237 -88 272 l-47 24 40 18 c44 20 94 75 103 115 10 42 10
  153 -1 191 -14 51 -69 100 -132 118 -34 9 -117 16 -230 19 l-178 5 0 -486z
  m377 355 c38 -28 53 -66 53 -130 0 -105 -65 -156 -212 -167 l-88 -7 0 167 0
  168 108 -4 c95 -3 111 -6 139 -27z"
            />
            <path
                d="M9640 1675 l0 -485 60 0 60 0 1 210 c1 116 2 216 3 223 1 9 44 13
  169 15 l167 3 0 -225 0 -226 60 0 60 0 0 475 c0 261 1 478 3 483 1 4 -26 8
  -60 10 l-63 3 0 -211 0 -210 -167 2 -168 3 -3 208 -2 207 -60 0 -60 0 0 -485z"
            />
            <path
                d="M10740 1675 l0 -485 65 0 65 0 0 225 0 226 158 -2 157 -1 3 51 3 51
  -160 0 -161 0 0 160 0 160 165 0 165 0 0 50 0 50 -230 0 -230 0 0 -485z"
            />
            <path
                d="M12140 1789 c0 -246 4 -386 12 -417 15 -63 58 -120 115 -157 46 -29
  52 -30 165 -30 104 0 122 3 160 23 60 33 102 94 122 174 13 56 16 127 16 423
  l0 355 -60 0 -60 0 -1 -367 c0 -402 -4 -434 -59 -474 -74 -54 -188 -47 -246
  15 -49 52 -54 97 -54 481 l0 345 -55 0 -55 0 0 -371z"
            />
            <path
                d="M12920 1675 l0 -485 56 0 55 0 -3 434 c-3 403 -2 431 13 406 9 -14
  20 -37 24 -51 4 -13 20 -55 37 -94 46 -108 83 -193 117 -271 17 -39 31 -74 31
  -77 0 -4 14 -35 30 -69 17 -34 30 -67 30 -73 0 -7 11 -32 23 -56 13 -24 32
  -68 41 -96 l18 -53 84 0 84 0 0 485 0 485 -55 0 -56 0 3 -420 c2 -231 0 -420
  -4 -420 -3 0 -12 17 -18 38 -7 20 -21 57 -32 82 -12 25 -29 65 -39 90 -30 77
  -70 173 -86 210 -9 19 -24 55 -34 80 -34 88 -71 176 -95 224 -13 28 -24 57
  -24 65 -1 41 -24 51 -114 51 l-86 0 0 -485z"
            />
            <path
                d="M13750 1676 l0 -486 168 0 c177 0 253 12 310 47 39 24 91 99 104 151
  16 61 22 399 9 506 -12 106 -27 144 -74 194 -49 51 -105 63 -324 69 l-193 6 0
  -487z m383 360 c68 -33 80 -62 91 -220 13 -186 -2 -406 -31 -443 -44 -60 -65
  -68 -194 -71 l-119 -4 0 382 0 383 108 -5 c75 -3 118 -10 145 -22z"
            />
            <path
                d="M14720 2128 c-7 -18 -16 -49 -20 -68 -4 -19 -19 -69 -33 -110 -54
  -158 -79 -237 -92 -285 -7 -27 -22 -77 -33 -110 -12 -33 -35 -105 -52 -160
  -16 -55 -37 -121 -45 -146 -8 -26 -15 -49 -15 -53 0 -3 32 -6 70 -6 60 0 70 2
  70 18 0 9 14 63 31 120 l32 102 173 0 172 0 12 -52 c7 -29 21 -83 31 -120 l20
  -68 69 0 c39 0 70 2 70 5 0 3 -15 51 -34 108 -33 100 -63 195 -127 407 -17 58
  -44 143 -60 190 -15 47 -30 99 -34 115 -3 17 -16 56 -27 88 l-21 57 -72 0 -73
  0 -12 -32z m129 -268 c17 -58 44 -149 61 -203 16 -53 30 -102 30 -107 0 -6
  -54 -10 -139 -10 l-139 0 15 53 c9 28 28 93 44 142 15 50 40 131 55 180 30
  101 25 105 73 -55z"
            />
            <path
                d="M15140 2110 l0 -50 120 0 120 0 0 -435 0 -435 65 0 65 0 0 435 0 435
  109 0 c121 0 120 0 113 68 l-4 32 -294 0 -294 0 0 -50z"
            />
            <path d="M15850 1675 l0 -485 60 0 60 0 0 485 0 485 -60 0 -60 0 0 -485z" />
            <path
                d="M16940 1675 l0 -485 55 0 56 0 -3 435 c-2 267 1 427 6 413 5 -13 22
  -51 38 -86 15 -34 28 -66 28 -71 0 -5 14 -36 30 -69 17 -34 30 -64 30 -68 0
  -4 14 -39 31 -78 17 -39 43 -100 59 -136 15 -36 36 -83 45 -105 10 -22 28 -66
  40 -97 13 -32 31 -75 41 -98 l18 -40 88 0 88 0 0 485 0 485 -60 0 -60 0 0
  -422 c0 -466 2 -453 -55 -303 -27 70 -80 197 -100 240 -7 17 -20 48 -28 70 -8
  22 -32 78 -52 125 -21 47 -46 108 -56 135 -10 28 -29 74 -43 103 l-26 52 -85
  0 -85 0 0 -485z"
            />
            <path
                d="M730 1313 c-118 -63 -135 -71 -152 -77 -10 -3 -18 -14 -18 -24 0 -18
  12 -20 136 -26 122 -7 156 -5 143 8 -2 3 -55 8 -117 12 -61 4 -112 10 -112 13
  0 3 45 27 100 54 60 28 100 53 100 62 0 20 -3 19 -80 -22z"
            />
            <path
                d="M3450 1326 c0 -8 8 -17 18 -21 9 -3 34 -21 54 -39 l37 -32 -69 -18
  c-39 -10 -70 -24 -70 -31 0 -9 22 -7 88 7 67 14 97 16 132 9 44 -10 68 -2 56
  17 -3 5 -27 13 -52 17 -34 5 -64 20 -110 56 -66 51 -84 59 -84 35z"
            />
            <path
                d="M18394 1288 c-8 -11 -74 -299 -74 -320 0 -5 9 -8 20 -8 19 0 24 10
  32 65 3 16 11 20 38 20 33 0 35 -2 44 -42 7 -32 14 -43 27 -43 25 0 24 6 -15
  185 -24 113 -36 151 -48 153 -9 2 -20 -3 -24 -10z m46 -200 c0 -5 -14 -8 -31
  -8 -23 0 -30 4 -26 14 3 8 9 32 12 53 11 68 18 69 31 8 7 -33 13 -63 14 -67z"
            />
            <path
                d="M19384 1285 c-4 -8 -4 -32 -2 -54 l3 -39 -34 6 c-50 8 -61 -13 -61
  -119 0 -107 7 -118 85 -117 l55 1 0 168 c0 163 -1 169 -20 169 -11 0 -23 -7
  -26 -15z m-4 -199 c0 -86 -9 -108 -35 -86 -11 9 -15 34 -15 85 0 77 3 85 32
  85 16 0 18 -9 18 -84z"
            />
            <path
                d="M20057 1293 c-4 -3 -7 -27 -7 -53 l0 -46 -33 4 c-51 6 -62 -15 -62
  -118 0 -108 7 -119 81 -119 l54 1 0 169 c0 134 -3 169 -13 169 -8 0 -17 -3
  -20 -7z m-12 -212 c0 -83 -7 -99 -34 -82 -9 6 -13 31 -14 78 -1 76 6 96 31 91
  14 -3 17 -17 17 -87z"
            />
            <path
                d="M20810 1131 c0 -164 1 -171 20 -171 19 0 20 7 20 99 0 83 3 100 17
  105 28 11 34 -8 35 -108 0 -95 0 -96 24 -96 24 0 24 1 24 101 0 55 -5 109 -10
  120 -11 20 -45 25 -70 9 -13 -8 -16 0 -20 48 -4 44 -9 58 -22 60 -17 3 -18
  -10 -18 -167z"
            />
            <path
                d="M19500 1260 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
  -13 0 -20 -7 -20 -20z"
            />
            <path
                d="M20540 1260 c0 -15 7 -20 25 -20 18 0 25 5 25 20 0 15 -7 20 -25 20
  -18 0 -25 -5 -25 -20z"
            />
            <path
                d="M19629 1268 c-1 -2 -3 -16 -5 -33 -2 -16 -8 -31 -14 -33 -14 -5 -13
  -32 3 -32 9 0 11 -20 9 -76 -5 -101 9 -134 54 -134 38 0 48 24 14 33 -18 5
  -20 14 -20 91 0 79 2 86 20 86 11 0 20 7 20 15 0 8 -9 15 -20 15 -19 0 -26 19
  -21 58 2 10 -36 19 -40 10z"
            />
            <path
                d="M20670 1235 c0 -24 -5 -35 -15 -35 -8 0 -15 -7 -15 -15 0 -8 7 -15
  15 -15 12 0 15 -17 15 -93 0 -59 4 -97 12 -105 18 -18 68 -15 68 3 0 8 -6 15
  -14 15 -20 0 -26 23 -26 106 0 67 2 74 20 74 11 0 20 7 20 15 0 8 -9 15 -20
  15 -16 0 -20 7 -20 35 0 28 -4 35 -20 35 -16 0 -20 -7 -20 -35z"
            />
            <path
                d="M18569 1183 c-10 -11 -14 -44 -14 -103 0 -102 10 -120 66 -120 42 0
  69 26 69 67 0 36 -36 30 -43 -7 -7 -35 -34 -40 -45 -10 -10 28 -5 141 7 153
  16 16 41 -1 41 -28 0 -19 5 -25 21 -25 19 0 21 4 16 37 -4 20 -12 40 -19 45
  -23 14 -85 9 -99 -9z"
            />
            <path
                d="M18762 1183 c-27 -52 -24 -179 5 -208 23 -23 79 -19 97 7 23 33 21
  68 -4 68 -15 0 -20 -7 -20 -24 0 -29 -12 -39 -34 -30 -14 5 -16 20 -14 88 3
  73 5 81 23 81 14 0 21 -8 23 -27 5 -38 42 -40 42 -2 0 48 -17 64 -65 64 -32 0
  -47 -5 -53 -17z"
            />
            <path
                d="M18940 1079 c0 -119 0 -120 23 -117 21 3 22 8 23 84 1 98 6 114 39
  114 18 0 25 5 25 20 0 23 -29 27 -48 8 -9 -9 -12 -9 -12 0 0 7 -11 12 -25 12
  l-25 0 0 -121z"
            />
            <path
                d="M19106 1178 c-20 -28 -21 -152 -3 -188 10 -19 21 -26 53 -28 44 -3
  58 7 69 51 6 23 4 27 -14 27 -14 0 -23 -8 -27 -25 -12 -48 -54 -18 -54 39 0
  25 3 26 51 26 l52 0 -5 45 c-7 59 -22 75 -69 75 -28 0 -42 -6 -53 -22z m78
  -30 c10 -39 7 -48 -18 -48 -31 0 -38 12 -30 45 8 32 39 33 48 3z"
            />
            <path
                d="M19500 1080 c0 -113 1 -120 20 -120 19 0 20 7 20 120 0 113 -1 120
  -20 120 -19 0 -20 -7 -20 -120z"
            />
            <path
                d="M19781 1191 c-32 -21 -36 -185 -4 -217 19 -19 78 -18 97 2 23 23 21
  64 -3 64 -13 0 -21 -8 -23 -22 -2 -15 -10 -23 -23 -23 -16 0 -21 8 -23 43 l-3
  42 45 0 46 0 0 41 c0 60 -14 79 -58 79 -20 0 -43 -4 -51 -9z m67 -59 c3 -29 1
  -32 -22 -32 -22 0 -26 5 -26 28 0 33 8 44 30 40 9 -2 16 -16 18 -36z"
            />
            <path
                d="M20270 1188 c0 -7 7 -62 17 -121 16 -98 19 -108 38 -105 17 3 22 15
  34 81 7 43 15 76 17 74 2 -2 9 -30 15 -63 15 -85 18 -94 38 -94 14 0 20 19 34
  108 21 129 21 132 2 132 -8 0 -16 -13 -20 -32 -3 -18 -9 -55 -13 -83 l-8 -50
  -15 80 c-11 59 -19 81 -32 83 -10 2 -18 -5 -21 -20 -3 -13 -11 -50 -17 -83
  l-13 -60 -8 50 c-4 28 -10 65 -13 83 -6 30 -35 47 -35 20z"
            />
            <path
                d="M20544 1186 c-3 -8 -4 -60 -2 -117 3 -99 4 -104 26 -107 22 -3 22 -2
  22 117 0 115 -1 121 -20 121 -11 0 -23 -6 -26 -14z"
            />
            <path
                d="M735 1099 c-38 -15 -80 -28 -93 -29 -22 0 -41 -25 -29 -37 3 -3 36 6
  74 20 37 13 90 31 117 38 56 16 74 40 29 38 -15 -1 -59 -14 -98 -30z"
            />
            <path
                d="M3367 1037 c-15 -34 -27 -68 -27 -74 0 -23 20 -14 32 15 7 15 13 28
  14 30 1 2 21 -6 45 -17 87 -39 173 -70 181 -65 13 8 9 24 -5 24 -8 0 -42 14
  -76 30 -35 17 -77 34 -93 37 -35 7 -45 25 -30 59 9 18 9 24 -1 24 -6 0 -24
  -29 -40 -63z"
            />
            <path
                d="M815 1021 c-16 -6 -21 -20 -25 -71 -4 -53 -9 -66 -31 -83 -33 -24
  -65 -11 -75 30 -6 21 -2 31 21 48 27 22 22 41 -8 30 -29 -12 -41 -52 -29 -94
  13 -43 41 -57 90 -45 38 10 51 32 52 87 0 27 5 57 10 68 15 28 56 24 76 -6 16
  -25 16 -27 -5 -49 -16 -17 -19 -26 -11 -31 14 -8 37 13 40 35 10 74 -34 108
  -105 81z"
            />
            <path
                d="M3310 907 c0 -15 7 -19 131 -85 88 -47 109 -53 109 -31 0 4 -24 19
  -52 33 -29 14 -82 42 -117 61 -73 40 -71 39 -71 22z"
            />
            <path
                d="M13421 873 c-16 -13 -22 -30 -24 -60 -1 -34 -5 -43 -19 -43 -11 0
  -18 -7 -18 -20 0 -11 7 -20 15 -20 8 0 15 -8 16 -17 0 -10 1 -61 2 -113 2 -88
  4 -95 22 -95 19 0 20 7 20 113 l0 112 28 0 c20 0 27 5 27 20 0 14 -7 20 -24
  20 -42 0 -42 66 0 76 15 4 24 13 24 25 0 25 -40 26 -69 2z"
            />
            <path
                d="M14825 880 c-11 -4 -31 -20 -45 -35 -24 -25 -25 -32 -25 -148 0 -80
  5 -130 13 -144 22 -40 68 -56 140 -50 35 3 68 9 73 14 6 6 9 50 7 99 -3 98 -6
  102 -73 90 -26 -5 -33 -11 -32 -26 1 -15 9 -20 30 -20 28 0 28 0 25 -57 l-3
  -58 -47 -3 c-42 -3 -49 0 -65 25 -15 22 -18 46 -17 135 2 123 9 138 63 138 43
  0 56 -8 65 -37 5 -16 15 -23 32 -23 27 0 29 6 14 44 -19 50 -99 80 -155 56z"
            />
            <path
                d="M15113 871 c-17 -11 -37 -29 -42 -40 -6 -11 -11 -74 -11 -141 l0
  -122 34 -34 c28 -28 42 -34 76 -34 34 0 48 6 76 34 43 43 47 86 9 86 -17 0
  -25 -5 -25 -18 0 -59 -90 -89 -110 -36 -13 34 -13 230 0 255 7 13 21 19 43 19
  39 0 52 -8 61 -36 8 -26 45 -39 52 -19 6 15 -18 66 -40 84 -27 23 -88 24 -123
  2z"
            />
            <path
                d="M4672 693 l3 -188 74 3 c133 4 155 37 149 211 -3 102 -3 103 -36 132
  -30 27 -38 29 -112 29 l-80 0 2 -187z m159 126 c19 -19 20 -30 17 -130 -4
  -125 -9 -135 -81 -145 l-47 -7 0 152 0 151 45 0 c32 0 51 -6 66 -21z"
            />
            <path
                d="M6230 808 c0 -68 -1 -70 -18 -55 -29 27 -87 22 -111 -9 -42 -54 -35
  -196 12 -229 28 -19 66 -19 95 1 12 8 22 10 22 5 0 -6 9 -11 20 -11 19 0 20 7
  20 185 0 178 -1 185 -20 185 -18 0 -20 -7 -20 -72z m-25 -92 c29 -21 27 -138
  -3 -169 -21 -20 -24 -21 -50 -7 -25 15 -27 21 -30 87 -2 55 1 74 13 87 20 20
  45 20 70 2z"
            />
            <path
                d="M7010 690 c0 -110 4 -190 9 -190 5 0 16 7 24 15 13 13 16 13 27 0 20
  -24 73 -19 100 10 41 44 41 179 -1 223 -21 23 -82 31 -94 12 -16 -25 -25 -5
  -25 55 0 58 -2 65 -20 65 -19 0 -20 -7 -20 -190z m138 -53 c3 -82 -11 -111
  -51 -105 -30 4 -47 43 -47 106 0 43 5 57 25 78 24 24 28 25 48 11 19 -12 22
  -25 25 -90z"
            />
            <path
                d="M7642 719 l3 -161 33 -29 c60 -54 171 -29 186 41 3 18 6 95 6 171 0
  132 -1 139 -20 139 -19 0 -20 -7 -21 -132 0 -75 -5 -148 -12 -168 -11 -31 -17
  -35 -50 -38 -26 -2 -45 2 -58 14 -17 15 -19 32 -19 171 l0 153 -26 0 -25 0 3
  -161z"
            />
            <path
                d="M8200 855 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
  -15 0 -20 -7 -20 -25z"
            />
            <path
                d="M9190 855 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
  -15 0 -20 -7 -20 -25z"
            />
            <path
                d="M10470 813 c0 -52 -3 -64 -12 -55 -7 7 -29 12 -50 12 -48 0 -70 -30
  -76 -105 -9 -106 19 -165 77 -165 16 0 37 7 45 16 9 8 16 11 16 5 0 -6 11 -11
  25 -11 l25 0 0 185 0 185 -25 0 c-24 0 -25 -2 -25 -67z m-12 -95 c19 -19 16
  -153 -4 -172 -19 -20 -35 -20 -60 -2 -16 12 -19 27 -19 84 0 44 5 75 14 85 16
  20 52 22 69 5z"
            />
            <path
                d="M12000 855 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
  -15 0 -20 -7 -20 -25z"
            />
            <path
                d="M13820 689 c0 -176 1 -190 18 -187 14 3 18 18 22 108 5 100 6 105 32
  119 24 14 28 14 47 -3 17 -16 21 -33 23 -120 3 -87 5 -101 21 -104 15 -3 17 7
  17 110 0 135 -10 158 -67 158 -20 0 -45 -7 -55 -17 -17 -15 -18 -13 -18 55 0
  65 -2 72 -20 72 -19 0 -20 -7 -20 -191z"
            />
            <path
                d="M14450 741 c0 -176 4 -196 45 -221 49 -30 107 -27 147 9 l33 29 3
  162 c3 160 3 162 -18 158 -20 -3 -21 -9 -23 -149 -2 -169 -10 -189 -77 -189
  -52 0 -60 24 -60 180 0 142 -3 160 -31 160 -18 0 -19 -9 -19 -139z"
            />
            <path
                d="M15562 843 c-26 -90 -85 -280 -93 -301 -14 -35 -11 -42 15 -42 20 0
  27 8 41 50 l17 50 63 0 62 0 13 -45 c7 -25 17 -46 21 -46 33 -5 44 -2 40 11
  -2 8 -9 38 -16 65 -7 28 -22 75 -33 105 -11 30 -27 85 -37 123 -17 65 -18 67
  -50 67 -28 0 -33 -4 -43 -37z m93 -182 c6 -20 3 -21 -49 -21 -31 0 -56 3 -56
  7 0 4 12 43 27 86 l27 78 22 -65 c12 -35 25 -74 29 -85z"
            />
            <path
                d="M910 829 c-36 -21 -85 -48 -108 -60 -42 -21 -61 -43 -49 -56 3 -3 33
  11 65 31 33 20 62 36 65 36 3 0 27 15 55 34 73 49 50 62 -28 15z"
            />
            <path
                d="M12777 858 c-9 -7 -21 -28 -26 -45 -9 -31 -8 -33 15 -33 14 0 24 6
  24 15 0 20 44 47 64 39 45 -17 36 -105 -12 -112 -36 -5 -30 -48 8 -55 35 -7
  47 -41 33 -90 -8 -28 -13 -32 -43 -32 -28 0 -37 5 -48 28 -16 34 -52 37 -52 5
  1 -43 33 -70 86 -75 42 -4 53 -1 76 21 47 45 46 128 -1 158 -19 12 -19 12 5
  35 18 16 24 32 24 63 0 57 -34 90 -93 90 -24 0 -51 -6 -60 -12z"
            />
            <path
                d="M16395 850 c-10 -11 -24 -20 -32 -20 -7 0 -13 -11 -13 -25 0 -28 24
  -34 34 -9 13 34 18 2 19 -143 2 -145 2 -148 25 -151 l22 -3 0 185 c0 174 -1
  186 -18 186 -11 0 -27 -9 -37 -20z"
            />
            <path
                d="M16617 850 c-51 -40 -45 -174 8 -198 33 -15 78 -15 93 0 13 13 15 8
  12 -42 -3 -52 -16 -70 -51 -70 -22 0 -32 5 -36 20 -6 24 -53 28 -53 5 0 -25
  50 -65 81 -65 74 0 95 31 103 150 6 92 -9 181 -35 203 -29 23 -90 22 -122 -3z
  m103 -46 c23 -60 1 -134 -40 -134 -41 0 -66 91 -40 141 16 30 68 26 80 -7z"
            />
            <path
                d="M16850 765 c0 -96 2 -105 19 -105 10 0 26 11 35 25 48 73 111 -27 76
  -120 -8 -22 -15 -26 -42 -23 -23 2 -36 10 -43 26 -13 29 -52 27 -47 -3 12 -67
  101 -88 153 -36 26 26 29 36 29 94 0 50 -5 70 -21 91 -22 28 -64 35 -100 15
  -19 -10 -20 -8 -17 43 l3 53 60 5 c47 4 61 9 63 23 3 15 -6 17 -82 17 l-86 0
  0 -105z"
            />
            <path
                d="M17141 853 c-37 -30 -51 -148 -32 -266 10 -59 26 -77 78 -84 32 -4
  44 0 71 22 30 25 32 30 32 96 0 55 -4 73 -20 89 -26 26 -68 36 -93 21 -16 -10
  -21 -10 -28 0 -16 27 18 109 46 109 15 0 45 -27 45 -41 0 -5 11 -9 25 -9 32 0
  33 23 0 55 -30 30 -92 34 -124 8z m89 -168 c13 -15 16 -91 5 -124 -9 -28 -60
  -29 -75 -2 -14 27 -13 97 2 121 15 23 50 26 68 5z"
            />
            <path
                d="M6503 829 c-16 -6 -23 -17 -23 -34 0 -18 -5 -25 -20 -25 -13 0 -20
  -7 -20 -20 0 -13 7 -20 20 -20 19 0 20 -7 20 -100 0 -94 1 -100 24 -115 15
  -10 33 -14 50 -9 34 8 34 34 1 34 -25 0 -25 0 -25 95 0 95 0 95 25 95 18 0 25
  5 25 20 0 15 -7 20 -24 20 -21 0 -25 5 -28 34 -3 30 -5 33 -25 25z"
            />
            <path
                d="M9338 832 c-10 -2 -18 -15 -20 -30 -2 -19 -10 -28 -25 -30 -34 -5
  -31 -42 3 -42 l25 0 -1 -87 c-1 -49 -1 -96 -1 -106 2 -34 91 -47 91 -12 0 7
  -10 15 -22 17 -22 3 -23 8 -26 96 l-3 92 26 0 c18 0 25 5 25 20 0 15 -7 20
  -24 20 -21 0 -25 6 -28 33 -2 24 -7 32 -20 29z"
            />
            <path
                d="M11873 829 c-16 -6 -23 -17 -23 -34 0 -18 -5 -25 -20 -25 -13 0 -20
  -7 -20 -20 0 -13 7 -20 20 -20 18 0 20 -7 20 -84 0 -47 4 -96 9 -109 10 -26
  48 -41 75 -31 24 9 19 32 -6 36 -22 3 -23 8 -26 96 l-3 92 26 0 c18 0 25 5 25
  20 0 15 -7 20 -24 20 -21 0 -25 5 -28 34 -3 30 -5 33 -25 25z"
            />
            <path
                d="M13693 829 c-16 -6 -23 -17 -23 -34 0 -18 -5 -25 -19 -25 -14 0 -19
  -6 -18 -20 1 -12 8 -20 19 -20 16 0 18 -10 18 -93 0 -59 5 -99 13 -110 22 -30
  87 -31 87 -2 0 8 -10 15 -22 17 -22 3 -23 8 -26 96 l-3 92 26 0 c18 0 25 5 25
  20 0 15 -7 20 -24 20 -21 0 -25 5 -28 34 -3 30 -5 33 -25 25z"
            />
            <path
                d="M3260 824 c0 -9 31 -33 109 -84 38 -25 68 -46 67 -47 -1 -2 -54 8
  -117 21 -62 14 -117 22 -121 20 -20 -13 6 -45 63 -79 9 -5 44 -29 77 -52 54
  -37 72 -43 72 -24 0 4 -18 17 -40 31 -22 14 -40 28 -40 32 0 5 -6 8 -14 8 -7
  0 -30 14 -51 30 -46 36 -39 37 81 5 109 -29 130 -31 138 -10 4 11 -9 25 -44
  47 -28 17 -50 35 -50 39 0 5 -7 9 -15 9 -8 0 -30 14 -48 30 -31 27 -67 40 -67
  24z"
            />
            <path
                d="M16057 823 c-4 -3 -7 -17 -7 -30 0 -16 -6 -23 -20 -23 -13 0 -20 -7
  -20 -20 0 -13 7 -20 20 -20 19 0 20 -7 20 -100 0 -95 1 -100 25 -116 16 -10
  34 -14 51 -10 31 8 27 28 -7 36 -23 6 -24 10 -27 98 l-3 92 31 0 c23 0 30 4
  30 20 0 16 -7 20 -30 20 -27 0 -30 3 -30 30 0 28 -17 40 -33 23z"
            />
            <path
                d="M18140 750 c0 -38 4 -50 15 -50 8 0 15 8 15 19 0 10 3 33 6 50 6 27
  4 31 -15 31 -19 0 -21 -6 -21 -50z"
            />
            <path
                d="M18285 788 c-8 -25 -65 -299 -65 -313 0 -8 9 -15 19 -15 16 0 21 9
  27 46 6 45 7 45 43 42 27 -2 37 -8 39 -23 8 -54 13 -65 32 -65 11 0 20 4 20 8
  0 7 -48 236 -65 310 -6 24 -43 31 -50 10z m42 -150 l10 -58 -29 0 c-23 0 -28
  4 -24 18 3 9 8 39 12 65 8 59 19 51 31 -25z"
            />
            <path
                d="M18440 750 c0 -38 4 -50 15 -50 10 0 15 10 15 28 0 15 3 37 6 50 5
  18 2 22 -15 22 -19 0 -21 -6 -21 -50z"
            />
            <path
                d="M18677 788 c-26 -21 -37 -64 -37 -151 0 -96 14 -153 40 -167 28 -15
  71 -12 92 7 17 15 18 15 18 0 0 -10 7 -17 15 -17 12 0 15 16 15 85 l0 85 -40
  0 c-29 0 -40 -4 -40 -15 0 -8 9 -15 20 -15 17 0 20 -7 20 -40 0 -49 -35 -79
  -69 -60 -19 10 -21 20 -21 123 0 62 4 118 9 125 5 8 21 12 37 10 22 -2 30 -10
  38 -35 6 -23 15 -33 29 -33 17 0 19 5 14 38 -4 20 -16 45 -28 55 -25 20 -88
  23 -112 5z"
            />
            <path
                d="M19335 788 c-2 -7 -3 -31 -2 -53 l2 -40 -37 3 c-28 3 -39 -1 -47 -16
  -26 -48 -19 -195 10 -212 7 -5 36 -8 66 -8 l53 1 0 168 c0 163 -1 169 -20 169
  -11 0 -23 -6 -25 -12z m-4 -135 c3 -61 -4 -149 -11 -157 -6 -5 -15 -4 -25 4
  -11 9 -15 32 -15 85 0 39 3 75 7 78 13 14 43 7 44 -10z"
            />
            <path
                d="M19760 631 l0 -169 57 1 c50 2 59 5 70 27 16 31 17 151 1 185 -9 21
  -23 29 -48 27 -3 0 -13 -4 -22 -7 -16 -6 -18 0 -18 49 0 49 -2 56 -20 56 -19
  0 -20 -7 -20 -169z m90 20 c14 -27 13 -124 -3 -145 -8 -11 -19 -15 -30 -10
  -14 5 -17 19 -17 83 0 43 3 81 7 84 12 13 32 7 43 -12z"
            />
            <path
                d="M20270 630 c0 -161 1 -170 19 -170 18 0 19 9 20 102 1 56 3 103 4
  105 2 1 23 -44 47 -102 24 -58 50 -105 57 -105 10 0 13 36 13 170 0 165 -1
  170 -21 170 -19 0 -20 -5 -19 -87 1 -49 1 -97 1 -108 -1 -11 -23 28 -48 88
  -26 60 -52 107 -60 107 -10 0 -13 -34 -13 -170z"
            />
            <path
                d="M20555 778 c-9 -39 -65 -308 -65 -313 0 -3 9 -5 20 -5 13 0 22 9 26
  28 14 62 14 63 47 60 29 -3 33 -7 41 -45 7 -35 13 -43 31 -43 l22 0 -36 168
  c-33 151 -38 167 -58 170 -16 2 -24 -3 -28 -20z m54 -190 c1 -5 -11 -8 -25 -8
  -26 0 -26 0 -19 57 11 88 18 94 31 23 7 -36 13 -68 13 -72z"
            />
            <path
                d="M20772 738 c-7 -35 -23 -111 -36 -170 l-23 -108 22 0 c19 0 24 7 29
  45 7 43 9 45 41 45 30 0 36 -4 41 -27 12 -57 16 -63 35 -63 10 0 19 2 19 4 0
  3 -16 77 -35 166 -19 89 -35 163 -35 166 0 2 -10 4 -23 4 -21 0 -25 -7 -35
  -62z m54 -108 c7 -49 6 -50 -21 -50 -23 0 -26 3 -22 23 3 12 9 44 13 72 6 41
  8 46 15 27 5 -12 11 -44 15 -72z"
            />
            <path
                d="M20987 788 c-27 -21 -37 -64 -37 -157 0 -97 12 -146 40 -161 26 -14
  83 -12 104 3 21 15 41 96 27 110 -17 17 -41 -1 -41 -32 0 -36 -23 -61 -50 -54
  -27 7 -31 30 -28 153 l3 105 29 3 c33 4 46 -10 46 -50 0 -23 4 -28 25 -28 14
  0 25 6 25 13 0 32 -23 88 -40 97 -26 14 -83 13 -103 -2z"
            />
            <path
                d="M4989 751 c-19 -15 -24 -30 -27 -84 -2 -37 -1 -82 2 -102 17 -86 156
  -85 181 3 5 19 3 22 -19 22 -19 0 -27 -7 -32 -24 -11 -44 -65 -41 -84 5 -21
  49 -9 59 70 59 66 0 70 1 70 23 0 41 -21 97 -40 107 -31 16 -96 12 -121 -9z
  m99 -23 c7 -7 12 -25 12 -40 0 -27 -2 -28 -50 -28 -54 0 -58 5 -35 50 16 32
  51 40 73 18z"
            />
            <path
                d="M5231 751 c-43 -43 -39 -195 6 -231 58 -46 152 -8 153 63 0 19 -42 5
  -52 -18 -12 -26 -18 -29 -53 -25 -26 3 -35 17 -35 58 l0 32 71 0 72 0 -5 52
  c-4 38 -12 57 -28 70 -31 25 -104 24 -129 -1z m97 -24 c6 -6 13 -24 17 -40 7
  -27 7 -27 -44 -27 -45 0 -51 2 -51 21 0 50 50 80 78 46z"
            />
            <path
                d="M5450 634 c0 -135 0 -135 23 -132 21 3 22 8 25 107 1 59 8 111 14
  118 6 8 23 13 37 11 32 -4 39 -27 40 -145 1 -86 3 -93 21 -93 19 0 20 7 20 89
  0 49 4 101 9 114 13 34 47 44 72 21 16 -15 19 -31 19 -121 0 -96 1 -103 20
  -103 19 0 20 7 20 110 0 139 -9 160 -65 160 -25 0 -48 -6 -55 -15 -11 -13 -15
  -13 -33 0 -26 18 -88 20 -106 3 -11 -10 -14 -10 -18 0 -3 6 -13 12 -24 12 -18
  0 -19 -9 -19 -136z"
            />
            <path
                d="M5867 752 c-36 -40 -47 -112 -27 -184 13 -46 43 -68 93 -68 42 0 87
  37 87 71 0 28 -35 23 -48 -6 -9 -20 -19 -25 -46 -25 -37 0 -46 12 -46 61 l0
  29 70 0 70 0 0 44 c0 65 -28 96 -89 96 -33 0 -53 -5 -64 -18z m94 -22 c12 -7
  19 -21 19 -40 0 -29 -1 -30 -50 -30 -45 0 -50 2 -50 22 0 25 27 58 47 58 8 0
  23 -5 34 -10z"
            />
            <path
                d="M6640 735 c-19 -31 -22 -47 -18 -111 3 -68 6 -79 31 -100 37 -32 87
  -32 124 0 25 21 28 32 31 100 4 64 1 80 -18 111 -20 33 -25 35 -75 35 -50 0
  -55 -2 -75 -35z m115 -22 c14 -13 16 -31 13 -85 -4 -74 -13 -90 -53 -90 -39 0
  -49 15 -53 87 -4 53 -1 70 13 85 22 24 58 26 80 3z"
            />
            <path
                d="M7289 751 c-19 -15 -24 -30 -27 -84 -6 -97 3 -134 38 -152 40 -21 83
  -19 114 6 32 25 36 69 7 69 -11 0 -23 -9 -26 -20 -14 -45 -69 -47 -86 -3 -21
  56 -15 63 59 63 38 0 71 2 74 5 12 12 -10 109 -27 121 -28 20 -98 18 -126 -5z
  m101 -30 c5 -11 10 -29 10 -40 0 -18 -6 -21 -50 -21 -41 0 -50 3 -50 18 0 51
  68 84 90 43z"
            />
            <path
                d="M7942 638 c3 -125 4 -133 23 -133 18 0 20 8 25 105 5 101 6 105 32
  120 25 13 29 13 45 -3 14 -14 19 -40 23 -120 4 -82 8 -102 20 -102 12 0 16 19
  18 102 4 132 -10 163 -68 163 -22 0 -45 -7 -54 -16 -15 -14 -16 -14 -16 0 0
  10 -9 16 -25 16 l-26 0 3 -132z"
            />
            <path
                d="M8200 635 c0 -128 1 -135 20 -135 19 0 20 7 20 135 0 128 -1 135 -20
  135 -19 0 -20 -7 -20 -135z"
            />
            <path
                d="M8290 765 c0 -10 71 -229 79 -245 5 -8 19 -15 31 -15 21 0 27 11 53
  90 15 50 35 109 42 133 14 41 14 42 -9 42 -19 0 -25 -11 -44 -67 -12 -38 -24
  -87 -28 -111 -4 -24 -10 -40 -14 -35 -8 9 -25 69 -44 153 -10 49 -15 55 -39
  58 -15 2 -27 1 -27 -3z"
            />
            <path
                d="M8570 740 c-43 -60 -39 -172 8 -215 37 -34 94 -34 127 0 31 30 33 65
  5 65 -11 0 -20 -6 -20 -14 0 -20 -39 -46 -59 -39 -25 9 -41 34 -41 65 l0 28
  70 0 70 0 0 41 c0 41 -14 78 -34 91 -6 4 -32 8 -58 8 -40 0 -49 -4 -68 -30z
  m108 -12 c7 -7 12 -25 12 -40 0 -27 -2 -28 -50 -28 -41 0 -50 3 -50 18 0 47
  58 80 88 50z"
            />
            <path
                d="M8792 638 c3 -125 4 -133 23 -133 18 0 20 8 25 105 l5 105 33 3 c26
  3 32 7 32 28 0 18 -5 24 -23 24 -13 0 -29 -6 -36 -12 -11 -10 -14 -10 -18 0
  -3 6 -13 12 -24 12 -19 0 -19 -7 -17 -132z"
            />
            <path
                d="M8970 750 c-20 -20 -26 -65 -12 -86 4 -6 33 -25 65 -43 64 -37 75
  -65 31 -82 -21 -7 -31 -6 -45 7 -11 10 -19 23 -19 31 0 7 -11 13 -24 13 -23 0
  -24 -2 -15 -32 12 -42 34 -58 80 -58 26 0 47 8 68 26 51 42 37 94 -33 125 -58
  25 -83 54 -66 74 17 21 47 19 67 -5 20 -23 53 -27 53 -5 0 30 -38 55 -85 55
  -32 0 -51 -6 -65 -20z"
            />
            <path
                d="M9192 638 c3 -110 6 -133 18 -133 12 0 15 23 18 133 2 127 2 132 -18
  132 -20 0 -20 -5 -18 -132z"
            />
            <path
                d="M9440 743 c17 -55 64 -189 74 -211 7 -16 6 -33 -2 -56 -18 -50 -16
  -68 10 -64 16 2 25 16 39 63 17 62 46 150 80 243 l18 52 -22 0 c-19 0 -26 -12
  -52 -87 -16 -49 -32 -92 -36 -97 -7 -8 -59 141 -59 170 0 9 -10 14 -29 14 -28
  0 -29 -2 -21 -27z"
            />
            <path
                d="M9830 661 c0 -94 3 -112 21 -135 23 -30 64 -35 95 -11 24 18 34 19
  34 2 0 -7 9 -13 20 -14 19 -2 20 4 20 133 0 127 -1 134 -20 134 -18 0 -20 -7
  -20 -84 0 -45 -5 -97 -11 -114 -11 -33 -42 -47 -71 -31 -16 8 -18 23 -18 119
  l0 110 -25 0 -25 0 0 -109z"
            />
            <path
                d="M10088 643 c2 -71 7 -132 12 -137 21 -21 30 10 30 103 0 82 3 101 18
  114 10 10 26 17 34 17 31 0 42 -31 46 -123 4 -109 6 -119 27 -111 22 9 22 210
  -1 242 -19 27 -78 30 -106 5 -17 -15 -18 -15 -18 0 0 11 -8 17 -22 17 -23 0
  -23 -1 -20 -127z"
            />
            <path
                d="M10613 750 c-45 -41 -43 -188 2 -227 57 -50 154 -12 155 60 0 4 -11
  7 -25 7 -15 0 -25 -6 -25 -14 0 -44 -76 -55 -85 -12 -14 63 -16 61 64 64 l73
  3 -4 50 c-7 67 -28 89 -88 89 -32 0 -53 -6 -67 -20z m101 -32 c30 -43 21 -58
  -34 -58 -44 0 -50 3 -50 21 0 56 54 80 84 37z"
            />
            <path
                d="M10830 635 c0 -128 1 -135 20 -135 24 0 29 19 32 130 l3 85 30 5 c21
  4 31 11 33 28 3 18 -1 22 -24 22 -15 0 -33 -8 -40 -17 -13 -17 -13 -17 -14 0
  0 10 -8 17 -20 17 -19 0 -20 -7 -20 -135z"
            />
            <path
                d="M11140 750 c-20 -20 -26 -65 -12 -86 4 -6 33 -25 65 -43 62 -35 75
  -65 35 -80 -32 -12 -57 -2 -64 25 -8 32 -44 33 -44 1 0 -80 130 -91 171 -16
  19 36 -6 74 -66 105 -57 29 -72 48 -54 70 16 20 46 17 66 -6 19 -22 53 -27 53
  -7 0 7 -7 23 -16 35 -21 30 -104 32 -134 2z"
            />
            <path
                d="M11392 763 c-38 -15 -56 -156 -26 -218 25 -54 98 -62 145 -16 36 36
  38 61 5 61 -18 0 -25 -6 -28 -22 -2 -18 -11 -24 -38 -26 -40 -4 -60 16 -60 60
  l0 28 75 0 75 0 0 38 c0 20 -9 52 -20 70 -18 29 -24 32 -67 31 -27 0 -54 -3
  -61 -6z m92 -45 c31 -44 22 -58 -39 -58 -48 0 -55 2 -55 20 0 26 34 60 59 60
  11 0 27 -10 35 -22z"
            />
            <path
                d="M11633 762 c-19 -12 -43 -78 -43 -119 0 -95 34 -143 100 -143 39 0
  90 41 90 73 0 26 -39 21 -49 -7 -13 -31 -46 -39 -74 -16 -19 15 -22 28 -22 83
  0 39 5 70 14 80 19 24 68 22 83 -3 14 -23 48 -27 48 -6 0 7 -9 25 -21 40 -17
  21 -29 26 -67 26 -26 0 -52 -4 -59 -8z"
            />
            <path
                d="M12000 634 c0 -124 2 -135 18 -132 15 3 17 19 20 136 2 127 2 132
  -18 132 -19 0 -20 -6 -20 -136z"
            />
            <path
                d="M12135 748 c-65 -93 -26 -248 62 -248 47 0 72 16 89 55 27 66 11 181
  -30 207 -6 4 -32 8 -58 8 -37 0 -50 -5 -63 -22z m113 -48 c16 -30 11 -122 -7
  -144 -8 -9 -27 -16 -42 -16 -23 0 -32 6 -40 28 -15 37 -7 141 12 153 24 16 63
  6 77 -21z"
            />
            <path
                d="M12362 638 c3 -117 5 -133 21 -136 15 -3 17 6 17 85 0 48 3 98 6 109
  8 31 46 49 72 35 19 -10 22 -22 24 -119 3 -99 4 -107 23 -107 18 0 20 8 23 97
  2 69 -2 107 -12 132 -14 33 -18 36 -57 36 -25 0 -50 -7 -61 -17 -17 -15 -18
  -15 -18 0 0 10 -8 17 -20 17 -20 0 -20 -5 -18 -132z"
            />
            <path
                d="M13156 739 c-23 -27 -26 -39 -26 -106 0 -69 2 -77 29 -104 48 -48
  130 -35 151 24 17 51 12 148 -10 185 -18 29 -24 32 -69 32 -43 0 -53 -4 -75
  -31z m108 -31 c31 -44 14 -166 -23 -169 -28 -2 -46 3 -53 14 -13 20 -9 152 4
  165 19 19 55 14 72 -10z"
            />
            <path
                d="M14090 740 c-26 -37 -35 -85 -25 -146 9 -61 41 -94 92 -94 43 0 93
  38 93 70 0 27 -33 26 -45 -2 -25 -53 -89 -35 -101 30 l-6 32 76 0 76 0 0 44
  c0 66 -28 96 -90 96 -43 0 -51 -4 -70 -30z m101 -10 c12 -7 19 -21 19 -40 0
  -30 -1 -30 -54 -30 -51 0 -54 1 -49 23 12 49 45 67 84 47z"
            />
            <path
                d="M15829 757 c-29 -22 -39 -55 -39 -127 0 -66 2 -74 29 -101 32 -31 75
  -38 114 -18 29 15 26 12 37 48 8 29 7 31 -16 31 -14 0 -24 -6 -24 -14 0 -21
  -38 -45 -60 -39 -11 3 -22 15 -26 27 -11 34 -7 135 6 151 20 25 80 16 80 -11
  0 -8 10 -14 24 -14 23 0 24 2 14 30 -5 17 -18 35 -28 40 -26 14 -92 12 -111
  -3z"
            />
            <path
                d="M933 725 c-84 -56 -107 -102 -81 -159 25 -56 106 -42 191 34 40 36
  47 47 47 79 0 46 -33 81 -76 81 -17 0 -51 -15 -81 -35z m121 -11 c9 -8 16 -23
  16 -32 -1 -20 -48 -66 -108 -107 -36 -24 -46 -26 -67 -17 -13 6 -27 23 -31 38
  -5 21 0 32 27 59 72 69 132 91 163 59z"
            />
            <path
                d="M19080 699 c-28 -11 -40 -26 -40 -51 0 -26 36 -23 43 3 8 30 41 21
  45 -13 3 -23 -2 -30 -32 -42 -41 -17 -58 -45 -54 -89 2 -24 9 -34 31 -42 24
  -8 31 -6 42 8 15 21 25 22 25 2 0 -9 9 -15 21 -15 21 0 21 3 17 105 -5 110
  -11 127 -53 138 -11 3 -31 1 -45 -4z m48 -156 c-2 -30 -7 -38 -23 -38 -26 0
  -31 31 -9 56 26 29 36 23 32 -18z"
            />
            <path
                d="M19483 700 c-35 -14 -45 -46 -41 -132 4 -87 17 -108 68 -108 37 0 59
  19 66 58 6 31 5 34 -17 30 -14 -2 -25 -11 -27 -23 -10 -53 -52 -31 -52 27 0
  27 2 28 51 28 l51 0 -6 47 c-8 65 -43 93 -93 73z m47 -59 c0 -30 -18 -45 -40
  -31 -20 12 -4 60 20 60 15 0 20 -7 20 -29z"
            />
            <path
                d="M18890 580 c0 -113 1 -120 20 -120 18 0 20 7 20 88 0 91 8 112 42
  112 11 0 18 7 18 20 0 22 -12 25 -41 10 -13 -7 -19 -7 -19 0 0 5 -9 10 -20 10
  -19 0 -20 -7 -20 -120z"
            />
            <path
                d="M19950 693 c0 -5 12 -61 27 -126 23 -100 25 -120 13 -127 -8 -5 -18
  -6 -22 -4 -4 3 -8 -4 -8 -15 0 -26 30 -28 58 -3 15 12 29 54 50 150 l29 132
  -22 0 c-20 0 -24 -8 -35 -81 -13 -83 -22 -87 -34 -14 -15 88 -17 95 -37 95
  -10 0 -19 -3 -19 -7z"
            />
            <path
                d="M3115 640 c-4 -6 30 -47 74 -92 93 -94 95 -100 49 -136 -14 -11 -22
  -11 -47 2 -17 9 -35 25 -41 35 -5 11 -18 22 -28 25 -11 4 -25 17 -31 30 -7 13
  -24 29 -37 35 -45 20 -26 -10 57 -90 87 -84 110 -93 146 -59 52 48 38 94 -59
  191 -38 38 -71 69 -73 69 -2 0 -7 -5 -10 -10z"
            />
            <path
                d="M1105 523 c-39 -42 -78 -85 -88 -95 -13 -14 -16 -23 -9 -30 7 -7 21
  2 44 28 18 22 52 59 75 84 36 38 41 41 36 20 -3 -14 -19 -53 -34 -88 -60 -133
  -46 -175 29 -92 26 29 66 73 90 97 46 48 48 78 3 41 -15 -13 -47 -47 -71 -75
  -24 -29 -46 -51 -48 -49 -4 5 46 156 57 171 19 25 20 45 3 54 -15 8 -31 -4
  -87 -66z"
            />
            <path
                d="M2768 363 c-23 -6 -22 -23 2 -134 12 -51 18 -94 16 -96 -6 -6 -50 42
  -73 80 -10 15 -21 27 -25 27 -4 0 -15 14 -23 31 -12 22 -22 29 -38 27 -37 -5
  -25 -50 63 -218 18 -35 38 -43 32 -12 -2 9 -22 54 -44 100 -42 89 -45 115 -7
  60 52 -73 126 -154 134 -146 6 6 -9 139 -25 211 -4 20 -6 37 -5 37 5 0 81
  -161 89 -187 4 -13 11 -23 16 -23 20 0 20 22 2 58 -11 20 -37 72 -57 115 -20
  42 -37 76 -38 76 -1 -1 -10 -3 -19 -6z"
            />
            <path
                d="M1495 348 c-5 -13 -45 -246 -45 -260 0 -5 7 -8 15 -8 8 0 32 25 53
  56 20 31 44 67 53 80 9 13 19 27 22 31 8 7 1 -57 -14 -146 -11 -69 0 -84 35
  -45 14 16 26 33 26 38 0 4 13 25 28 45 47 61 73 109 63 116 -10 5 -39 -33 -84
  -109 -10 -17 -27 -39 -37 -49 -16 -17 -15 -9 7 89 19 86 21 109 11 115 -21 14
  -27 11 -42 -26 -8 -19 -19 -35 -23 -35 -5 0 -24 -26 -43 -57 -42 -70 -49 -54
  -20 48 11 40 20 84 20 96 0 25 -18 41 -25 21z"
            />
            <path
                d="M2953 344 c-15 -40 47 -69 70 -33 16 26 1 49 -33 49 -19 0 -33 -6
  -37 -16z"
            />
            <path
                d="M1298 329 c-25 -14 -23 -56 3 -69 16 -9 25 -8 40 6 24 21 24 36 1 57
  -21 20 -21 20 -44 6z"
            />
            <path
                d="M1797 218 c-22 -92 -45 -214 -41 -217 10 -10 22 18 42 94 31 117 34
  145 18 145 -8 0 -16 -10 -19 -22z"
            />
            <path
                d="M2387 222 c-38 -42 -6 -222 39 -222 12 0 14 4 7 13 -6 6 -18 45 -28
  85 -16 67 -16 74 -1 97 21 32 60 33 80 2 27 -40 49 -147 37 -174 -10 -21 -9
  -24 6 -21 16 3 18 14 15 83 -2 65 -6 86 -27 117 -23 34 -30 38 -69 38 -29 0
  -48 -6 -59 -18z"
            />
            <path
                d="M1908 209 c-21 -12 -33 -70 -20 -96 6 -10 23 -25 39 -33 43 -22 63
  -41 63 -62 0 -10 5 -18 10 -18 6 0 10 13 10 30 0 25 -8 35 -49 62 -53 35 -66
  58 -47 81 26 33 86 24 86 -13 0 -11 5 -20 10 -20 15 0 12 33 -6 58 -16 24 -65
  30 -96 11z"
            />
            <path
                d="M2120 100 c0 -78 3 -100 14 -100 11 0 12 18 8 90 l-5 90 41 0 c56 0
  72 -24 72 -112 0 -54 3 -68 15 -68 12 0 15 13 15 59 0 69 -15 118 -40 131 -10
  6 -41 10 -69 10 l-51 0 0 -100z"
            />
            <path
                d="M1860 20 c0 -11 6 -20 13 -20 7 0 13 9 13 20 0 11 -6 20 -13 20 -7 0
  -13 -9 -13 -20z"
            />
        </g>
    </svg>
);

export default Logo;
