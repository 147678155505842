import other from './other';
import pages from './pages';
import external from './externalUser';
import useAuth from 'hooks/useAuth';
import * as React from 'react';
import { useState } from 'react';
// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
    const { user } = useAuth();
    const [userType, setUserType] = useState('');
    const tabsVisibility = {
        'Super Administrator': [other],
        'Admin User': [other],
        'System User': [pages],
        'External User': [external]
    };
    React.useEffect(() => {
        if (user) {
            setUserType(user.userType);
        }
    }, [user]);
    return { menuItem: { items: tabsVisibility[userType] || [] } };
};

export default MenuItems;
