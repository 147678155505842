// third-party
import { FormattedMessage } from 'react-intl';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddTaskIcon from '@mui/icons-material/AddTask';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import { IconBrandChrome, IconHelp, IconSitemap, IconUsers, IconTemplate } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconTemplate
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'sample-docs-roadmaps',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: DashboardIcon,
            breadcrumbs: true
        },
        {
            id: 'my-projects',
            title: <FormattedMessage id="my-projects" />,
            type: 'item',
            url: '/myProject',
            icon: NoteAddIcon,
            breadcrumbs: true
        },
        {
            id: 'approvals',
            title: <FormattedMessage id="approvals" />,
            type: 'collapse',
            icon: AddTaskIcon,
            children: [
                {
                    id: 'approval-project',
                    title: <FormattedMessage id="approval-project" />,
                    type: 'item',
                    url: '/otherProject'
                },
                {
                    id: 'purchase-list',
                    title: <FormattedMessage id="purchase-list" />,
                    type: 'item',
                    url: '/purchase-list'
                },
                {
                    id: 'account-list',
                    title: <FormattedMessage id="account-list" />,
                    type: 'item',
                    url: '/account-list'
                }
            ]
        },

        {
            id: 'external-review-head',
            title: <FormattedMessage id="external-review-head" />,
            type: 'collapse',
            icon: RateReviewIcon,
            children: [
                {
                    id: 'submit-review',
                    title: <FormattedMessage id="submit-review" />,
                    type: 'item',
                    url: '/submitExternalReview'
                },
                {
                    id: 'view-external-review',
                    title: <FormattedMessage id="view-external-review" />,
                    type: 'item',
                    url: '/approvarExternalReviewRecords'
                }
            ]
        },
        {
            id: 'committee',
            title: <FormattedMessage id="committee" />,
            type: 'item',
            url: '/committeeList',
            icon: Diversity3Icon,
            breadcrumbs: true
        }
    ]
};

export default pages;
